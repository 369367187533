import React from "react";
import ContentHeader from "../../navigation/header/ContentHeader";
import useResizeObserver from "../../../hooks/useResizeObserver";
import PageFiltersCard from "./PageFiltersCard";
import FilteredContentWrapper from "../../wrappers/FilteredContentWrapper";
import useElementCoordinates from "../../../hooks/useElementCoordinates";
import {isMobileHorizontalScreen, isMobileVerticalScreen} from "../../../utils/displayUtils";

export default function PageContent({
                                        tabs, selectedTabName, onSelectTab, onScroll, filtersEl, contentEl, additionalEl,
                                        displayContentHeader, displaySearchInput, onChangeSearchInput, searchInputAmount, beforeSearchInputAmount, searchInputValue,
                                        searchInputLabel, displayFiltersBtn, displayXlsxBtn, displayTableSettingsBtn, displayIpRangesBtn, onClickTableSettingsBtn,
                                        isTableSettingsExpanded, xlsxData, headerAdditionalEl
                                    }) {

    const leftMenuWidth = useResizeObserver(document.getElementById("main-menu")).width;
    const filtersWidth = useResizeObserver(document.getElementById("filters")).width | 0;
    const layoutMargins = 20;
    const pageHeaderEl = document.getElementById("page-header");
    const pageHeaderCoord = useElementCoordinates(pageHeaderEl);
    const pageHeaderHeight = pageHeaderEl?.clientHeight;
    const contentHeaderHeight = useResizeObserver(document.getElementById("contentHeader"))?.fullHeight;
    const layoutWidth = useResizeObserver(document.body).width - leftMenuWidth - filtersWidth - layoutMargins;
    let contentCardBodyHeight = window.innerHeight - pageHeaderHeight - (displayContentHeader ? contentHeaderHeight : 0);
    let scrollableHeight = contentCardBodyHeight;
    if (isMobileVerticalScreen() || isMobileHorizontalScreen()) {
        scrollableHeight = scrollableHeight - pageHeaderCoord?.bottom;
    }

    const getContentEl = () => {
        return (
            filtersEl
                ? <FilteredContentWrapper> {contentEl}</FilteredContentWrapper>
                : contentEl
        )
    };

    return (
        <div className="wrapper" id="layout">
            <div className="card">
                {displayContentHeader &&
                    <ContentHeader tabs={tabs} selectedTabName={selectedTabName} onSelectTab={onSelectTab}
                                   additionalElements={headerAdditionalEl} displaySearchInput={displaySearchInput} onChangeSearchInput={onChangeSearchInput}
                                   searchInputAmount={searchInputAmount} beforeSearchInputAmount={beforeSearchInputAmount} searchInputLabel={searchInputLabel} searchInputValue={searchInputValue}
                                   displayFiltersBtn={displayFiltersBtn} displayXlsxBtn={displayXlsxBtn}
                                   displayTableSettingsBtn={displayTableSettingsBtn} displayIpRangesBtn={displayIpRangesBtn}
                                   onClickTableSettingsBtn={onClickTableSettingsBtn} isTableSettingsExpanded={isTableSettingsExpanded}
                                   xlsxData={xlsxData}/>}
                <div className={"card-body" + (displayContentHeader ? "" : " padding10")} style={{height: contentCardBodyHeight + "px", overflow: "hidden"}}>
                    <div className="layout" style={{width: layoutWidth + "px"}} >
                        <div className={"scrollable" + (displayContentHeader ? "" : " full-height")} id="scrollable"
                             style={{height: scrollableHeight + "px", maxHeight: scrollableHeight + "px"}}
                             onScroll={(e) => onScroll && onScroll(e.target)} >
                            {getContentEl()}
                        </div>
                        {additionalEl}
                    </div>
                    {filtersEl && <PageFiltersCard content={filtersEl}/>}
                </div>
            </div>
        </div>
    )
}
