import React, {useEffect, useRef} from "react";
import IosCheckbox from "../../../common/checkboxes/IosCheckbox";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {isMobileScreen} from "../../../../utils/displayUtils";
import OfferEditInput from "./OfferEditInput";
import OfferCard from "../offerCard/OfferCard";
import DatePicker from "../../../common/pickers/DatePicker";
import {useSelector} from "react-redux";
import {deductTimezoneOffset, getRusDateString} from "../../../../utils/dateUtils";
import {LinkCopyPopup} from "../../../common/linkCopyPopup/LinkCopyPopup";
import {sort} from "../../../../utils/sortUtils";
import OfferEditMinDataTable from "./OfferEditMainDataTable";


export default function OfferEditMainData({offer, children, toggleCheckboxSwitch, handleInputChange, onSelectIncomeDateMin,
                                              errorFields, selectOfferImg, uploadedImg, buttons, excludeChildFromClone, addChildToClone}) {

    const offerCategoriesDropDown = useSelector(state => state.dictionaries.categories);
    const subscriptionServices = useSelector(state => state.subscriptionServiceReducer.subscriptionServices);
    const incomesWithChildren = useSelector(state => state.offerIncomeReducer.incomesWithChildren);

    const childRef = useRef();

    useEffect(() => {
        if (!offer) {
            return;
        }

    }, [offer]);


    const onSelectCategory = (value) => {
        if (!value) {
            return;
        }
        handleInputChange("category", value);
    };

    const onSelectSubscriptionService = (value) => {
        if (!value) {
            return;
        }
        handleInputChange("subscriptionService", value);
        handleInputChange("operatorType", value.operatorType);
    };

    const getTestUrl = (offerId) => {
        if (!offerId || !offer.urlTemplate) {
            return;
        }
        let testUrl = offer.urlTemplate;
        testUrl = testUrl.replace('{offer_id}', offerId);
        testUrl = testUrl.replace('{partner_id}', '23316');
        testUrl = testUrl.replace('{channel_id}', '23316');
        testUrl = testUrl.replace('{source_id}', '0');
        const currentDate = getRusDateString(deductTimezoneOffset(new Date())).split(".").join("");
        testUrl = testUrl + '&pt_id=' + 'test_' + offerId + '_' + currentDate;
        testUrl = testUrl + '&traf_src=kadam&site_id=123456';
        return testUrl;
    };

    const getCurrentIncomes = (offer) => {
        let offerIncomes = incomesWithChildren;

        if (!offerIncomes) {
            return;
        }
        offerIncomes = sort(offerIncomes, "billingType", 1);
        let currentIdx = null;
        const result = [];
        offerIncomes.forEach(income => {
            if (result.length === 0) {
                currentIdx = 0;
                result.push(income);
            } else if (result[currentIdx].billingType !== income.billingType) {
                currentIdx += 1;
                result.push(income);
            } else if (result[currentIdx].offerIncomeId < income.offerIncomeId) {
                result[currentIdx] = income;
            }
        });
        return result;
    };

    const muiSelectStyle = {root: {minWidth: isMobileScreen() ? 250 : 100}};

    return (
        offer &&
        <div style={{display: "block", width: "100%"}}>
            <div className="card no-bor-rad-top" style={{marginBottom: "0", overflowY: "auto"}}>
                <div className="card-body fortabs row">
                    <div className="col-12 col-sm-12 col-md-9 float-left">
                        <div className="row">
                            <div className="">
                                <div className="form-group edit-offer">
                                    <IosCheckbox label="Скрыт от новичков" labelClassName="float-left"
                                                 isChecked={offer.hidden}
                                                 onClickFunction={() => toggleCheckboxSwitch("hidden")}/>
                                </div>
                            </div>
                            <div className="">
                                <div className="form-group edit-offer">
                                    <IosCheckbox label="Горячий оффер" labelClassName="float-left"
                                                 isChecked={offer.recommend}
                                                 onClickFunction={() => toggleCheckboxSwitch("recommend")}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-6">
                                <label className="fs10px">Тематика</label>
                                <MaterialUISelect styles={muiSelectStyle}
                                                  dropdownData={offerCategoriesDropDown}
                                                  selectedValue={offer.category}
                                                  displayedField="name" dataField="id"
                                                  onSelectCallbackFunction={onSelectCategory}/>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <label className="fs10px">Подписочный сервис</label>
                                <MaterialUISelect styles={muiSelectStyle}
                                                  dropdownData={subscriptionServices}
                                                  selectedValue={offer.subscriptionService}
                                                  displayedFields={["id", "name"]} dataField="id" isSearchable
                                                  onSelectCallbackFunction={onSelectSubscriptionService}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4">
                                <OfferEditInput label="Название для партнёров" value={offer.name}
                                                onChange={(e) => handleInputChange("name", e.target.value)}
                                                hasError={errorFields.includes("name")}/>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <OfferEditInput label="Описание" value={offer.description}
                                                onChange={(e) => handleInputChange("description", e.target.value)}
                                                hasError={errorFields.includes("description")}/>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <OfferEditInput label="Комментарий" value={offer.comment}
                                                onChange={(e) => handleInputChange("comment", e.target.value)}
                                                hasError={errorFields.includes("comment")}/>
                            </div>
                            <div className="col-12 col-sm-3 col-md-3">
                                <OfferEditInput label="EUP (в нац.валюте страны)" value={offer.eup}
                                                onChange={(e) => handleInputChange("eup", e.target.value)}
                                                hasError={errorFields.includes("eup")}/>
                            </div>
                            <div className="col-12 col-sm-3 col-md-3">
                                <label className="fs10px">Мин.дата подписки для выплат</label>
                                <DatePicker inputType="simple"
                                            date={offer.incomeDateMin && new Date(offer.incomeDateMin)} autoClose
                                            onSelectDate={onSelectIncomeDateMin}/>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6">
                                <OfferEditInput label="Ограничения/Таргетинг"
                                                value={offer.term}
                                                onChange={(e) => handleInputChange("term", e.target.value)}
                                                hasError={errorFields.includes("term")}/>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-12 col-sm-12 col-md-12">
                                <OfferEditInput label="Шаблон ссылки" value={offer.urlTemplate}
                                                onChange={(e) => handleInputChange("urlTemplate", e.target.value)}
                                                hasError={errorFields.includes("urlTemplate")}/>
                            </div>
                            <br/>
                            <div style={{paddingTop: "15px"}} className="col-12 col-sm-12 col-md-12 float-left">
                                {offer && !offer.parentId
                                && <OfferEditMinDataTable offer={offer}
                                                           children={children}
                                                           getTestUrl={getTestUrl}
                                                           onCopyLink={(url) => childRef.current.handleClickCopy(url)}
                                                          excludeChildFromClone={excludeChildFromClone}
                                                          addChildToClone={addChildToClone}
                                />}
                            </div>

                        </div>

                    </div>

                    <div className="col-12 col-sm-12 col-md-3 float-left">
                        <div className="row">
                            <div className="form-group">
                                <label className="fs10px" id="LabelOpisanie">Скриншот оффера</label>
                                <input type="file" name="upload" className="form-control" onChange={selectOfferImg}
                                       accept='image/jpeg,image/png'/>
                            </div>
                            <div className="offer-block">
                                <OfferCard offer={offer} offerIncomes={getCurrentIncomes(offer)} isChecked={false}
                                           img={uploadedImg}/>
                            </div>
                        </div>
                    </div>

                </div>
                {buttons}
            </div>

            <LinkCopyPopup ref={childRef}/>
        </div>
    );
};