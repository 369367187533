import React, {Component} from 'react';
import BasePage from "../BasePage";
import StatisticTable from "./table/StatisticTable"
import StatisticFiltersCard from "./filters/StatisticFiltersCard";
import {connect} from "react-redux";
import LineChart from "../../common/charts/LineChart";
import DoughnutChart from "../../common/charts/DoughnutChart";
import {borderColors, getDefaultGroupChartDataType, getGroupChartDataType} from "../../../utils/chartUtils";
import {deleteSelectedPreset, loadUserPresets, savePreset} from "../../../actions/presetActions";
import ChartLegend from "../../common/charts/ChartLegend";
import NoContentMessage from "../../common/NoContentMessage";
import {
    ASC,
    CLICKS,
    CVR,
    DESC,
    INCOME,
    LEADS,
    MANAGER_ANALYSIS,
    MANAGER_MAIN,
    PARTNER_ANALYSIS,
    PARTNER_MAIN,
    REBILLS,
    SUBSCRIPTIONS,
    LAST_7_DAYS, LAST_17_2_DAYS,
    TRAFFICBACKS,
    MARGIN,
    GROSS_REVENUE,
    STATISTIC_TABLE_COLUMNS,
    STATISTIC_TABLE_FILTERS,
    LOCAL_STORAGE_STATISTIC_FILTERS,
    REFERRALS_AMOUNT,
    MANAGER_REFERRER,
    MANAGER_LTV_ANALYSIS,
    BUYOUTS,
    INCOME_MINUS_OPERATORS_REVENUE,
    SUBSCRIBE_1,
    STATISTIC_REPORT_CHART,
    STATISTIC_REPORT_TABLE
} from "../../../utils/constants";
import {
    addTimezoneOffset,
    fromDate,
    isDateGroupData,
    isDateGroupType,
    toDate
} from "../../../utils/dateUtils";
import {
    clearStatisticData, exportDetailedStatistic,
    getDetailedStatistic,
    getStatistic
} from "../../../actions/statisticActions";
import {
    calculateLtvRow,
    getAvailableGroupTypes, getCR,
    getDefaultSelectedGroups,
    getStatisticGroupName,
    isDateColumn,
    calcSubscriptionsNum,
    ltvAnalysisColumns,
} from "../../../utils/statisticUtils";
import {addOrRemove, addOrRemoveAndReturnNewArray, isEmptyArr} from "../../../utils/arrayUtils";
import {getPartnerId} from "../../../utils/authUtils";
import {searchByString} from "../../../utils/searchUtils";
import {presetsAccessType} from "../../../utils/presetsUtils";
import {isMobileHorizontalScreen, isMobileScreen} from "../../../utils/displayUtils";
import {showWarning} from "../../../actions/warningActions";
import GroupChartDataSelect from "./filters/GroupChartDataSelect";
import {activeOffers} from "../../../actions/offersActions";
import {loadActiveOperators, loadGroupedOperators, loadOperatorTypes} from "../../../actions/dictionaryActions";
import {loadPartnersFilterData} from "../../../actions/managerPartnersActions";
import {loadServiceGroups} from "../../../actions/serviceGroupsActions";
import StatisticDetailsModal from "./modal/StatisticDetailsModal";
import {getFromLocalStorage, setToLocalStorage} from "../../../utils/localStorageUtils";
import {
    managerStatisticDesktopPageTabs,
    managerStatisticMobilePageTabs,
    partnerStatisticPageTabs
} from "../../../utils/pageTabsUtils";
import {loadLtvServiceTypes} from "../../../actions/ltvServiceTypesActions";
import {loadRosLines} from "../../../actions/rosLinesActions";
import PageContent from "../../common/page/PageContent";
import {toggleOpenFilterBlock} from "../../../actions/navigationActions";
import {emptyString} from "../../../utils/stringUtils";

const warningMsg = "Данные по подпискам при анализе трафика могут расходиться с основной статистикой, поскольку " +
    "формируются с привязкой ко времени клика, а не ко времени подписки, и могут не попадать в выбранный период.";
const newPreset = {label: "Новый пресет"};
const DOUGHNUT_LINES_MAX_NUMBER = 9;

const getDefaultDisplayedLines = (tabName) => {
    if (!tabName) {
        return;
    }
    switch (tabName) {
        case PARTNER_MAIN:
            return [CLICKS, SUBSCRIPTIONS, REBILLS, TRAFFICBACKS];
        case MANAGER_MAIN:
            return [CLICKS, SUBSCRIPTIONS, MARGIN, GROSS_REVENUE];
        case PARTNER_ANALYSIS:
        case MANAGER_ANALYSIS:
            return [CLICKS, TRAFFICBACKS, CVR, LEADS];
        case MANAGER_LTV_ANALYSIS:
            return [SUBSCRIPTIONS, BUYOUTS, REBILLS, INCOME, INCOME_MINUS_OPERATORS_REVENUE];
        default:
            return [CLICKS];
    }
};

const detailedStatisticRowsLimit = 10000;

class StatisticPage extends Component {

    state = {
        selectedTab: this.props.isLoggedAsPartner ? PARTNER_MAIN : MANAGER_MAIN,
        needScrollToBottom: false,
        filters: {
            chartBtnSelected: false,
            tableBtnSelected: false,
            hasChanges: true,
            fromDate: fromDate(LAST_7_DAYS),
            toDate: toDate(LAST_7_DAYS),
            period: LAST_7_DAYS,
            groups: getDefaultSelectedGroups(),
            countries: [],
            operators: [],
            offers: [],
            partners: [],
            serviceType: null,
            ltvServiceType: null,
            payoutType: {label: "Выкуп", value: SUBSCRIBE_1},
            serviceGroups: [],
            financeFee: "",
            buyoutRate: "",
        },
        rosLine: [],
        ltvServiceTypes: [],
        ltvInterval: 120,
        searchValue: '',
        filtersPreset: null,
        columnsPreset: null,
        showTable: true,
        showColumnsLineChart: false,
        showGroupDonutChart: false,
        showGroupLineChart: false,
        tableBtnSelected: false,
        chartBtnSelected: false,
        hiddenColumns: [],
        expandedRows: [],
        displayedLines: getDefaultDisplayedLines(this.props.isLoggedAsPartner ? PARTNER_MAIN : MANAGER_MAIN),
        displayedGroupChartLines: [],
        groupChartSelectedDataType: getDefaultGroupChartDataType(),
        isTableSettingsExpanded: false,
        columnsSettingsHasChanges: false,
        sorting: {column: SUBSCRIPTIONS, direction: DESC},
        shouldBeSorted: false,
        tableHeaderGroupName: "Дни",
        filteredTableData: [],
        chartData: [],
        pageWidth: window.innerWidth,
        detailedStatisticRequestParams: [],
        isDetailsModalOpen: false,
        detailedStatisticColumn: null,
        totalDetailedStatisticRows: null,
        xlsxReportData: null,
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.props.activeOffers();
        this.props.loadActiveOperators();
        if (this.props.isLoggedAsPartner) {
            this.props.loadOperatorTypes();
        } else {
            this.props.loadGroupedOperators();
            this.props.loadPartnersFilterData();
            this.props.loadServiceGroups();
            this.props.loadLtvServiceTypes();
            this.props.loadRosLines();
        }
        const accessType = presetsAccessType();
        this.props.loadUserPresets(STATISTIC_TABLE_FILTERS, accessType, null, (filters) => this.filtersPresetsLoadingCallback(filters));
        this.props.loadUserPresets(STATISTIC_TABLE_COLUMNS, accessType);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = (e) => {
        this.setState({pageWidth: window.innerWidth})
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {sorting, selectedTab} = this.state;
        let {groups} = this.state.filters;
        if (groups.length === 0 && selectedTab !== MANAGER_REFERRER) {
            groups = getDefaultSelectedGroups();
            this.setState({filters: {...this.state.filters, groups: groups}})
        }

        if (this.props.ltvServiceTypes && this.props.rosLines && (this.props.ltvServiceTypes !== prevProps.ltvServiceTypes
            || this.props.rosLines !== prevProps.rosLines)) {
            const ltvServiceTypes = this.props.ltvServiceTypes.map(item => {
                return {...item, line: (this.props.rosLines.find( line => line.lineId == item.lineId )?.line)}
            });
            this.setState({ltvServiceTypes: ltvServiceTypes});
        }


        if (this.props.tableData  !== prevProps.tableData) {
            const firstGroup = groups[0];
            let sorting;
            let tableHeaderGroupName = getStatisticGroupName(firstGroup && firstGroup.value, selectedTab);
            if (isDateColumn(firstGroup && firstGroup.value)) {
                sorting = {column: 'groupName', direction: ASC};
            } else {
                sorting = {column: SUBSCRIPTIONS, direction: DESC};
            }

            let filteredTableData = this.filterTableStatisticDataBySearchString(this.state.searchValue);
            filteredTableData = filteredTableData ? sortTableData(filteredTableData, sorting, 0, groups) : filteredTableData;
            this.setState({filteredTableData: filteredTableData, shouldBeSorted: false, tableHeaderGroupName: tableHeaderGroupName, sorting: sorting})
        } else if (this.state.searchValue !== prevState.searchValue || this.state.shouldBeSorted) {
            let filteredTableData = this.state.filteredTableData;
            if (this.state.searchValue !== prevState.searchValue) {
                filteredTableData = this.filterTableStatisticDataBySearchString(this.state.searchValue);
            }
            this.calculateLtvAnalysisColumns();
            filteredTableData = sortTableData(filteredTableData, sorting, 0, groups);
            this.setState({filteredTableData: filteredTableData, shouldBeSorted: false})
        }
        if (this.props.chartData  !== prevProps.chartData) {
            const chartData = this.filterChartStatisticDataBySearchString(this.state.searchValue);
            this.setState({chartData: chartData});
        }
        if (this.props.columnsPreset && this.props.columnsPreset !== prevProps.columnsPreset) {
            const hiddenColumns = this.props.columnsPreset.data && this.props.columnsPreset.data.hiddenColumns;
            if (hiddenColumns) {
                this.setState({hiddenColumns: hiddenColumns});
            }
        }
        if ((this.state.selectedTab === PARTNER_ANALYSIS || this.state.selectedTab === MANAGER_ANALYSIS)
             && !localStorage.getItem('isStatAnalysisMsgRead')) {
            this.props.showWarning(warningMsg, () => localStorage.setItem('isStatAnalysisMsgRead', "true"));
        }
    };

    calculateLtvAnalysisColumns() {
        if (this.state.filteredTableData?.length > 0) {
            this.state.filteredTableData.forEach(row => calculateLtvRow(row, this.state, this.state.ltvServiceTypes));
            this.setState({filteredTableData: this.state.filteredTableData, shouldBeSorted: true});
        }
    };


    filtersPresetsLoadingCallback = (filtersPresets) => {
        const localStorageFilters = getFromLocalStorage(LOCAL_STORAGE_STATISTIC_FILTERS);
        if (localStorageFilters) {
            const availableGroups = getAvailableGroupTypes(this.state.selectedTab);
            localStorageFilters.groups = localStorageFilters.groups.filter(group => availableGroups.includes(group.value));
            this.setState({filters: localStorageFilters});
        } else {
            const defaultPreset = filtersPresets.find(preset => preset.defaultPreset);
            defaultPreset && this.selectPreset(defaultPreset);
        }
        this.createStatisticReport();
    };

    selectTab = (tabName) => {
        const currentTab = this.state.selectedTab;
        if (currentTab === tabName) {
            return;
        }
        const localStorageFilters = getFromLocalStorage(LOCAL_STORAGE_STATISTIC_FILTERS);
        if (localStorageFilters) {
            const availableGroups = getAvailableGroupTypes(tabName);
            localStorageFilters.groups = localStorageFilters.groups.filter(group => availableGroups.includes(group.value));
            if (localStorageFilters.groups?.length === 0) localStorageFilters.groups = getDefaultSelectedGroups(tabName);
        }
        this.setState({selectedTab: tabName, displayedLines: getDefaultDisplayedLines(tabName), filters: localStorageFilters ? localStorageFilters : this.state.filters, searchValue: ''});
        this.props.clearStatisticData();
    };

    clearFilters = (shouldClearDates) => {
        const filters = this.state.filters;
        const isLtvAnalysis = this.state.selectedTab === MANAGER_LTV_ANALYSIS;
        filters.chartBtnSelected = false;
        filters.tableBtnSelected = false;
        filters.hasChanges = true;
        filters.groups = getDefaultSelectedGroups(this.state.selectedTab);
        filters.countries = [];
        filters.operators = [];
        filters.partners = [];
        filters.serviceType = null;
        filters.ltvServiceType = null;
        filters.buyoutRate = "";
        filters.financeFee = "";
        filters.payoutType = {label: "Выкуп", value: SUBSCRIBE_1};
        filters.serviceGroups = [];
        filters.offers = [];
        if (shouldClearDates) {
            filters.fromDate = isLtvAnalysis ? fromDate(LAST_17_2_DAYS) : fromDate(LAST_7_DAYS);
            filters.toDate = isLtvAnalysis ? toDate(LAST_17_2_DAYS) : toDate(LAST_7_DAYS);
            filters.period = isLtvAnalysis ? LAST_17_2_DAYS : LAST_7_DAYS;
        }
        setToLocalStorage(LOCAL_STORAGE_STATISTIC_FILTERS, filters, 24);
        this.setState({filters: filters, tableBtnSelected: false, chartBtnSelected: false, filtersPreset: null});
    };

    selectFromDate = (fromDate) => {
        const filters = this.state.filters;
        filters.fromDate = fromDate;
        filters.period = null;
        filters.hasChanges = true;
        this.setState({filters: filters});
        setToLocalStorage(LOCAL_STORAGE_STATISTIC_FILTERS, filters, 24);
    };

    selectToDate = (toDate) => {
        const filters = this.state.filters;
        filters.toDate = toDate;
        filters.period = null;
        filters.hasChanges = true;
        this.setState({filters: filters});
        setToLocalStorage(LOCAL_STORAGE_STATISTIC_FILTERS, filters, 24);
    };

    selectPeriod = (period, setNewPreset) => {
        const filters = this.state.filters;
        filters.fromDate = fromDate(period);
        filters.toDate = toDate(period);
        filters.period = period;
        filters.hasChanges = true;
        this.setState({filters: filters});
        setToLocalStorage(LOCAL_STORAGE_STATISTIC_FILTERS, filters, 24);
        setNewPreset && this.setState({filtersPreset: newPreset})
    };

    selectGroup = (data, setNewPreset) => {
        if (!data || !data.number) {
            return;
        }
        const filters = this.state.filters;
        let groups = filters.groups.slice();
        if (!data.value && groups.length === 1 && data.number === groups[0].number) {
            return;
        }
        const currentGroupIndex = groups.findIndex(group => data.number === group.number);
        if (currentGroupIndex >= 0) {
            groups[currentGroupIndex] = data;
        } else {
            groups.push(data)
        }

        groups = groups.filter(group => group.value);
        filters.groups = groups.sort((a, b) => a.number - b.number);
        filters.hasChanges = true;
        this.setState({filters: filters});
        setToLocalStorage(LOCAL_STORAGE_STATISTIC_FILTERS, filters, 24);
        setNewPreset && this.setState({filtersPreset: newPreset});
    };

    handleChangeFilterData = (filterName, data, setNewPreset) => {
        this.setFilterData(filterName, data, setNewPreset);
    };

    setFilterData = (filterName, value, setNewPreset) => {
        let filters = this.state.filters;
        if (filterName === "countries" && value.length > 0) {
            const availableOperatorIds = value.flatMap(country => country.operatorIds);
            let operators = this.state.filters.operators;
             operators = operators.filter(operator => availableOperatorIds.includes(operator.operatorId));
            filters = {...filters, [filterName]: value, operators: operators, hasChanges: true};
            this.setState({filters: {...filters, countries: value, operators: operators, hasChanges: true}});
        } else {
            filters = {...filters, [filterName]: value, hasChanges: true};
        }
        this.setState({filters: filters});
        switch (filterName) {
            case "buyoutRate":
            case "financeFee":
                this.calculateLtvAnalysisColumns();
        }
        setToLocalStorage(LOCAL_STORAGE_STATISTIC_FILTERS, filters, 24);
        setNewPreset && this.setState({filtersPreset: newPreset})
    };

    selectPreset = (preset) => {
        const filters = {...this.state.filters};
        this.setState({filtersPreset: preset});
        const presetData = preset.data;
        if (presetData.period) {
            filters.period = presetData.period;
            filters.fromDate = fromDate(presetData.period);
            filters.toDate = toDate(presetData.period);
        } else {
            filters.period = null;
            filters.fromDate = presetData.fromDate ? new Date(presetData.fromDate) : filters.fromDate;
            filters.toDate = presetData.toDate ? new Date(presetData.toDate) : filters.toDate;
        }
        filters.groups = presetData.groups ? [...presetData.groups] : [];

        const availableGroups = getAvailableGroupTypes(this.state.selectedTab);
        filters.groups = filters.groups.filter(group => availableGroups.includes(group.value));

        filters.countries = presetData.countries ? [...presetData.countries] : [];
        filters.operators = presetData.operators ? [...presetData.operators] : [];
        filters.offers = presetData.offers ? [...presetData.offers] : [];
        filters.partners = presetData.partners ? [...presetData.partners] : [];
        filters.serviceGroups = presetData.serviceGroups ? [...presetData.serviceGroups] : [];
        filters.ltvServiceType = presetData.ltvServiceType;
        filters.payoutType = presetData.payoutType;
        filters.buyoutRate = presetData.buyoutRate;
        filters.financeFee = presetData.financeFee;
        filters.hasChanges = true;
        setToLocalStorage(LOCAL_STORAGE_STATISTIC_FILTERS, filters, 24);
        this.setState({filters: filters})
    };

    changeSearchValue = (value) => {
        const filteredTableData =  this.filterTableStatisticDataBySearchString(value);
        const chartData = this.filterChartStatisticDataBySearchString(value);
        this.setState({searchValue: value, filteredTableData: filteredTableData, chartData: chartData});
    };

    onChangeTableSorting = (column, direction) => {
        this.setState({sorting: {column: column, direction: direction}, shouldBeSorted: true});
    };


    saveColumnsPreset = () => {
        const preset = this.props.columnsPreset ? this.props.columnsPreset : {};
        preset.label = "Настройки таблицы статистики";
        preset.defaultPreset = true;
        preset.type = STATISTIC_TABLE_COLUMNS;
        preset.accessType = presetsAccessType();
        preset.data = {
            hiddenColumns: this.state.hiddenColumns,
        };
        this.savePreset(preset);
    };

    saveFiltersPreset = (preset) => {
        this.savePreset(preset, () => this.selectPreset(preset));
    };

    savePreset = (preset, onSuccessCallbackFunction) => {
        this.props.savePreset(preset, onSuccessCallbackFunction, true);
    };

    deletePreset = () => {
        const preset = this.state.filtersPreset;
        if (preset && preset.id) {
            this.props.deleteSelectedPreset(preset);
        }
        this.clearFilters(true);
    };

    onLoadStatisticDataCallback = () => {
        if (!this.state.showColumnsLineChart) {
            this.selectGroupChartDataType(this.state.groupChartSelectedDataType);
            this.setGroupChartDisplayedLines(this.state.groupChartSelectedDataType.value, this.props.chartData);
        }
        if (this.state.selectedTab === MANAGER_LTV_ANALYSIS){
            this.calculateLtvAnalysisColumns();
        }
    };

    setGroupChartDisplayedLines = (dataType, chartData) => {
        const {showGroupDonutChart} = this.state;
        const statisticType = this.state.selectedTab;
        let displayedLines = [];

        if (chartData) {
            if (showGroupDonutChart) {
                if (dataType !== CVR) {
                    chartData.sort((a, b) => b[dataType] - a[dataType]);
                } else {
                    chartData.sort((a, b) => calcSubscriptionsNum(b, statisticType) - calcSubscriptionsNum(a, statisticType));
                }
                let count = 0;
                chartData.forEach(data => {
                    if (data[dataType] > 0) {
                        if (++count <= DOUGHNUT_LINES_MAX_NUMBER) {
                            displayedLines.push(data.groupName);
                        }
                    }
                });
                if (count > DOUGHNUT_LINES_MAX_NUMBER) {
                    displayedLines.push("Остальные");
                }
            } else {
                displayedLines = this.sortNestedTotals(chartData, dataType).slice(0, DOUGHNUT_LINES_MAX_NUMBER / 2 + 1).map(item => item.name);
            }
        }
        this.setState({displayedGroupChartLines: displayedLines})
    };

    toggleRowsExpansion = (rowId) => {
        const expandedRows = addOrRemoveAndReturnNewArray(this.state.expandedRows, rowId);
        this.setState({expandedRows: expandedRows})
    };

    toggleExpandTableSettings = (isExpanded) => {
        this.setState({isTableSettingsExpanded: isExpanded});
        if (!isExpanded && this.state.columnsSettingsHasChanges) {
            this.saveColumnsPreset();
            this.setState({columnsSettingsHasChanges: false});
        }
        if (isExpanded && this.props.isFilterBlockOpen && (isMobileScreen() || isMobileHorizontalScreen())) {
            this.props.toggleOpenFilterBlock(false, false);
        }
    };

    toggleColumnsLineChartLegend = (line) => {
        let lines = this.state.displayedLines;
        lines = addOrRemove(lines, line);
        this.setState({displayedLines: lines})
    };

    toggleGroupChartLegend = (line) => {
        let lines = this.state.displayedGroupChartLines;
        lines = addOrRemove(lines, line);
        if (lines.length > 0) {
            this.setState({displayedGroupChartLines: lines})
        }
    };

    showOrHideTableColumn = (columnName) => {
        const hiddenColumns = addOrRemove(this.state.hiddenColumns, columnName);
        this.setState({hiddenColumns: hiddenColumns, columnsSettingsHasChanges: true})
    };

    handleLtvInterval = (ltvInterval) => {
        if (ltvInterval) {
            this.setState({ltvInterval: ltvInterval});
            this.calculateLtvAnalysisColumns();
        }
        return this.state.ltvInterval;
    };

    selectGroupChartDataType = (dataType) => {
        if (!dataType || !getGroupChartDataType(this.state.selectedTab).find(item => item.value === dataType.value)) {
            dataType = getDefaultGroupChartDataType(this.state.selectedTab);
        }
        this.setState({groupChartSelectedDataType: dataType});
        this.setGroupChartDisplayedLines(dataType.value, this.props.chartData);
    };

    toggleGroupsData = (firstGroupNumber, secondGroupNumber) => {
        let groups = this.state.filters.groups;

        const firstGroup = groups.find(group => group.number === firstGroupNumber);
        const secondGroup = groups.find(group => group.number === secondGroupNumber);
        if (firstGroup) {
            firstGroup.number = secondGroupNumber;
        }
        if (secondGroup) {
            secondGroup.number = firstGroupNumber;
        }
        groups = sort(groups, 'number', 1);
        const filters = this.state.filters;
        filters.groups = groups;
        this.setState({filters: filters})
        setToLocalStorage(LOCAL_STORAGE_STATISTIC_FILTERS, filters, 24);

    };

     getTableColumns = () => {
         const hiddenColumns = this.state.hiddenColumns;

         if (this.state.selectedTab === MANAGER_LTV_ANALYSIS
             && this.props.tableColumns.length > 0
             && this.props.tableColumns.length != ltvAnalysisColumns.length) {
             this.props.tableColumns.splice(0, this.props.tableColumns.length);
             ltvAnalysisColumns.forEach(item => !item?.hidden && this.props.tableColumns.push({...item, label: item.label + (item.ltvInterval ? this.state.ltvInterval : "")}));
         }
         return this.props.tableColumns.filter(column => !hiddenColumns.includes(column.name));
    };

    createStatisticReport = () => {
        this.setState({
            tableBtnSelected: true,
            chartBtnSelected: false,
            showTable: true,
            showColumnsLineChart: false,
            showGroupDonutChart: false,
            showGroupLineChart: false,
        });
        if (this.state.filters.hasChanges) {
            this.props.getStatistic(this.state.filters, this.state.selectedTab, STATISTIC_REPORT_TABLE, this.onLoadStatisticDataCallback);
            (isMobileScreen() || isMobileHorizontalScreen()) && this.props.toggleOpenFilterBlock(false, false);
        }
    };

    buildChart = () => {
        if (!this.state.chartBtnSelected || this.state.filters.hasChanges) {
            const groups = this.state.filters.groups;
            let isGroupingByDate = isDateColumn(groups[0]?.value);
            let isGrouping2ByDate = isDateColumn(groups[1]?.value);
            let filters = this.state.filters;

            if (this.state.filters.hasChanges) {
                if (isGrouping2ByDate && !isGroupingByDate) {
                        filters = {...this.state.filters, groups: [groups[1], groups[0]]};
                        isGroupingByDate = true;
                        isGrouping2ByDate = false;
                }
                this.props.getStatistic(filters, this.state.selectedTab, STATISTIC_REPORT_CHART, this.onLoadStatisticDataCallback);
                (isMobileScreen() || isMobileHorizontalScreen()) && this.props.toggleOpenFilterBlock(false, false);
            }
            this.setState({
                tableBtnSelected: false,
                chartBtnSelected: true,
                showColumnsLineChart: isGroupingByDate && (isGrouping2ByDate || !filters.groups[1]?.value),
                showGroupDonutChart: !isGroupingByDate && !isGrouping2ByDate,
                showGroupLineChart: isGroupingByDate && (filters.groups[1]?.value && !isGrouping2ByDate),
                showTable: false});
        } else {
            this.createStatisticReport();
        }
    };

    getChartData = () => {
        const {showColumnsLineChart, showGroupDonutChart, showGroupLineChart} = this.state;
        if (showColumnsLineChart) {
            return this.getColumnsLineChartData();
        }
        if (showGroupDonutChart) {
            return this.getGroupDonutChartData();
        }
        if (showGroupLineChart) {
            return this.getGroupLineChartData();
        }
        return {labels: [], chartData: []};
    };

    getColumnsLineChartData = () => {
        let lineChartData = {labels: [], chartData: []};
        const reduxChartData = this.state.chartData;
        reduxChartData.sort((a1, a2) => a1.groupName.localeCompare(a2.groupName));
        const chartLines = this.props.lineChartColumns;
        chartLines.forEach(function (column) {
            lineChartData.chartData.push({lineName: column.name, lineData: []})
        });

        reduxChartData.forEach(function (data) {
            lineChartData.labels.push(data.groupName);

            chartLines.forEach(function (column, i) {
                const value = data[column.name] ? data[column.name] : 0;
                lineChartData.chartData[i].lineData.push(value);
            })
        });
        return lineChartData;
    };

    getGroupLineChartData = () => {
        const dataType = this.state.groupChartSelectedDataType.value;
        let lineChartData = {labels: [], chartData: []};
        const reduxChartData = this.state.chartData;
        let idx;
        const statisticType = this.state.selectedTab;

        reduxChartData.sort((a1, a2) => a1.groupName.localeCompare(a2.groupName));

        let allLine = []; let allCR = [];
        let otherLine = []; let otherCR = [];

        reduxChartData.forEach(point => {
            lineChartData.labels.push(point.groupName);
            allLine.push(0);
        });

        const totals = this.sortNestedTotals();
        const chartLines = totals.slice(0, DOUGHNUT_LINES_MAX_NUMBER).map(item => item.name);
        chartLines.forEach(line => {
            lineChartData.chartData.push({lineName: line, lineData: allLine.slice(0)}); // init by 0-line
        });
        if (totals.length > DOUGHNUT_LINES_MAX_NUMBER) {
            otherLine = allLine.slice(0);
            lineChartData.chartData.push({
                lineName: chartLines[chartLines.push("Остальные") - 1],
                lineData: otherLine,
            });
        }
        lineChartData.chartData.push({lineName: chartLines[chartLines.push("Все") - 1], lineData: allLine});

        const calculateLineCR = (line, data, idx, statisticType) => {
            for  (let key in line[idx]) {
                line[idx][key] += data[key];
            }
            return getCR(line[idx], statisticType)
        };

        reduxChartData.forEach((point, pointIdx) => {
            if (dataType === CVR){
                allCR.push({[SUBSCRIPTIONS]: 0, [BUYOUTS]: 0, [CLICKS]: 0, [TRAFFICBACKS]: 0, [LEADS]: 0});
                otherCR.push({[SUBSCRIPTIONS]: 0, [BUYOUTS]: 0, [CLICKS]: 0, [TRAFFICBACKS]: 0, [LEADS]: 0});
            }
            if (point.nestedData) {
                point.nestedData.forEach(item => {
                    if ((idx = chartLines.indexOf(item.groupName)) !== -1) {
                        lineChartData.chartData[idx].lineData[pointIdx] = item[dataType];
                    } else {
                        if (dataType !== CVR)
                            otherLine[pointIdx] += item[dataType];
                        else
                            otherLine[pointIdx] = calculateLineCR(otherCR, item, pointIdx, statisticType);
                    }
                    if (dataType !== CVR)
                        allLine[pointIdx] += item[dataType];
                    else
                        allLine[pointIdx] = calculateLineCR(allCR, item, pointIdx, statisticType);
                });
            }
        });
        return lineChartData;
    };

    sortNestedTotals = (chartData = this.state.chartData, dataType = this.state.groupChartSelectedDataType.value) => {
        const statisticType = this.state.selectedTab;
        let nestedTotal = {};
        let totals = [];

        chartData.forEach(point => {
            if (point.nestedData) {
                point.nestedData.forEach(item => {
                    if (!nestedTotal[item.groupName])
                        nestedTotal[item.groupName] = {[SUBSCRIPTIONS]: 0, [BUYOUTS]: 0, [LEADS]: 0, [dataType]: 0};
                    nestedTotal[item.groupName][SUBSCRIPTIONS] += item[SUBSCRIPTIONS];
                    nestedTotal[item.groupName][BUYOUTS] += item[BUYOUTS];
                    nestedTotal[item.groupName][LEADS] += item[LEADS];
                    nestedTotal[item.groupName][dataType] += item[dataType];
                });
            }
        });
        for (let name in nestedTotal) {
            totals.push({name: name, ...nestedTotal[name]});
        }
        return totals.sort((i1, i2) => (dataType !== CVR)
            ? i2[dataType] - i1[dataType]
            : calcSubscriptionsNum(i2, statisticType) - calcSubscriptionsNum(i1, statisticType));
    };

    getGroupDonutChartData = () => {
        const dataType = this.state.groupChartSelectedDataType.value;
        let doughnutChartData = {allLabels: [], labels: [], chartData: []};
        const reduxChartData = this.state.chartData;
        const displayedLines = this.state.displayedGroupChartLines;
        reduxChartData.sort((a, b) => b[dataType] - a[dataType]);
        let data = [];
        let colors = [];
        let other = 0;

        reduxChartData.forEach(function (item, idx) {
            if (item[dataType] > 0) {
                if (idx < DOUGHNUT_LINES_MAX_NUMBER) {
                    if (displayedLines.includes(item.groupName)) {
                        data.push(Math.round(item[dataType]));
                        colors.push(borderColors[idx]);
                        doughnutChartData.labels.push(item.groupName);
                    }
                    doughnutChartData.allLabels.push(item.groupName);
                } else {
                    other += item[dataType];
                }
            }
        });
        if (other > 0) {
            if (displayedLines.includes('Остальные')) {
                other = Math.round(other);
                data.push(other);
                colors.push(borderColors[DOUGHNUT_LINES_MAX_NUMBER]);
                doughnutChartData.labels.push('Остальные');
            }
            doughnutChartData.allLabels.push('Остальные');
        }
        doughnutChartData.chartData.data = data;
        doughnutChartData.chartData.backgroundColor = colors;
        return doughnutChartData;
    };

    toggleDetailsModalOpen = () => {
        this.setState({...this.state, isDetailsModalOpen: !this.state.isDetailsModalOpen});
    };

    showDetails = (rowId, detailedStatisticColumn, detailedStatisticColumnValue) => {
        const dayInMs = 24*60*60*1000;
        const filters = this.state.filters;
        let operatorIds = filters.operators.length > 0 ? filters.operators.map(operator => operator.operatorId)
            : filters.countries.length > 0
                ? filters.countries.flatMap(country => country.operatorIds)
                : [];
        const initSearchData = {
            type: detailedStatisticColumn.toUpperCase(),
            isReferralsDetails: detailedStatisticColumn === REFERRALS_AMOUNT,
            from: addTimezoneOffset(filters.fromDate).getTime(),
            to: addTimezoneOffset(filters.toDate).getTime() + dayInMs,
            partnerIds: detailedStatisticColumn === REFERRALS_AMOUNT
                ? [rowId]
                : this.props.isLoggedAsPartner
                    ? [getPartnerId()]
                    : filters.partners.length > 0
                        ? filters.partners.filter(item => !item.isGroup).map(item => item.id)
                        : [],
            partnerTypeIds: !this.props.isLoggedAsPartner && filters.partners.length > 0 ? filters.partners.filter(item => item.isGroup).map(item => item.partnerTypeId) : [],
            serviceGroupIds: !this.props.isLoggedAsPartner && filters.serviceGroups.length > 0 ? filters.serviceGroups.map(service => service.id) : [],
            offerIds: filters.offers.length > 0 ? filters.offers.flatMap(offer => offer.ids) : [],
            operatorIds: operatorIds,
            limit: detailedStatisticRowsLimit,
        };
        const searchData = detailedStatisticColumn === REFERRALS_AMOUNT ? initSearchData : this.findDetailsSearchData(rowId, initSearchData);
        this.setState({...this.state,
            detailedStatisticRequestParams: searchData,
            isDetailsModalOpen: true,
            detailedStatisticColumn: detailedStatisticColumn,
            totalDetailedStatisticRows: detailedStatisticColumnValue});
        this.props.getDetailedStatistic(searchData);
    };

    exportToCsv = () => {
        this.props.exportDetailedStatistic(this.state.detailedStatisticRequestParams);
    };

    findDetailsSearchData = (detailsRowId, searchData) => {
        const groupDataArr = this.findGroupDataItems(detailsRowId, this.state.filteredTableData);
        groupDataArr.forEach(groupDataItem => searchData = this.mergeGroupDataItems(searchData, groupDataItem));
        return searchData;
    };


    findGroupDataItems = (rowId, tableData) => {
        for (let i = 0; i < tableData.length; i++) {
            const row = tableData[i];
            if (row.rowId === rowId) {
                return [row.groupData];
            } else if (row.isGroupRow && row.isOpen) {
                const groupDataArr = this.findGroupDataItems(rowId, row.nestedData);
                if (groupDataArr?.length > 0) {
                    return [...groupDataArr, row.groupData];
                }
            }
        }
        return [];
    };

    mergeGroupDataItems = (searchData, groupDataItem) => {
        if (searchData.from && groupDataItem.from && searchData.to && groupDataItem.to) {
            searchData.from = (searchData.from > groupDataItem.from) ? searchData.from : groupDataItem.from;
            searchData.to = (searchData.to < groupDataItem.to) ? searchData.to : groupDataItem.to;
            return searchData;
        }
        else if (groupDataItem.countryId) {
            searchData.countryIds = [groupDataItem.countryId];
            return searchData;
        } else if (groupDataItem.operatorId) {
            searchData.operatorIds = [groupDataItem.operatorId];
            return searchData;
        } else if (groupDataItem.offerId) {
            searchData.offerIds = [groupDataItem.offerId];
            return searchData;
        } else if (groupDataItem.partnerId) {
            searchData.partnerIds = [groupDataItem.partnerId];
            return searchData;
        } else if (groupDataItem.partnerTypeId) {
            searchData.partnerTypeIds = [groupDataItem.partnerTypeId];
            return searchData;
        } else if (groupDataItem.serviceGroupId) {
            searchData.serviceGroupIds = [groupDataItem.serviceGroupId];
            return searchData;
        } else if (groupDataItem.subscriptionServiceExternalId) {
            searchData.subscriptionServiceExternalIds = [groupDataItem.subscriptionServiceExternalId];
            return searchData;
        } else {
            return  Object.assign({}, searchData, groupDataItem);
        }
    };

     filterTableStatisticDataBySearchString = (searchStr) => {
         const tableData = this.props.tableData;
        if (isEmptyArr(tableData) || emptyString(searchStr)) {
            return tableData;
        }
        return searchByString(tableData, ["groupName"], searchStr);
    };

     filterChartStatisticDataBySearchString = (searchStr) => {
        const chartData = this.props.chartData;
        if (isEmptyArr(chartData) || emptyString(searchStr)) {
            return chartData;
        }
        const {showGroupLineChart } = this.state;
        let result;
         if (showGroupLineChart) {
             result = [];
             chartData.forEach(dataItem => {
                 const newDataItem = {...dataItem};
                 newDataItem.nestedData = searchByString(dataItem.nestedData, ["groupName"], searchStr);
                 result.push(newDataItem);
             });
         } else {
             result = searchByString(chartData, ["groupName"], searchStr);
         }
         this.setGroupChartDisplayedLines(this.state.groupChartSelectedDataType.value, result);
         return result;
    };


    getXlsxData = (data) => {
        if (!data || data === this.state.xlsxReportData) {
            return;
        }
        this.setState({xlsxReportData: data});
    };

    renderChart = () => {
        const {loading, isLoggedAsPartner} = this.props;
        const {showColumnsLineChart, showGroupDonutChart, showGroupLineChart, displayedLines, displayedGroupChartLines, chartBtnSelected} = this.state;
        if (!chartBtnSelected) {
            return null;
        }
        const chartData = this.getChartData();

        const isMultipleLegendRows = this.state.pageWidth <= 1024;
        const isMobileDisplay = isMobileScreen();
        return (
            <>
                {
                    showColumnsLineChart &&
                    <>
                        {
                            (chartData && chartData.labels && chartData.labels.length > 0) || loading
                                ?
                                <div className="row">
                                    <div className={isMultipleLegendRows || loading ? 'col-12' : 'col-9'} style={{paddingTop: '10px'}}>
                                        <LineChart {...chartData} isLoading={loading} displayedLines={displayedLines} height={365} isLoggedAsPartner={isLoggedAsPartner}/>
                                    </div>
                                    <div className={isMultipleLegendRows ? 'col-12' : 'col-3'}>
                                        <ChartLegend type="line"
                                                     lines={chartData && chartData.chartData ? chartData.chartData.map(data => data.lineName) : []}
                                                     displayedLines={displayedLines}
                                                     toggleChartLegend={this.toggleColumnsLineChartLegend}/>
                                    </div>
                                </div>
                                :
                                !loading && <NoContentMessage/>
                        }
                    </>
                }
                {
                    showGroupLineChart &&
                    <>
                        {
                            (chartData && chartData.labels && chartData.labels.length > 0) || loading
                                ?
                                <div className="row">
                                    <div className={isMultipleLegendRows || loading ? 'col-12' : 'col-8'} style={{paddingTop: '10px'}}>
                                        <LineChart {...chartData} isLoading={loading} displayedLines={displayedGroupChartLines} height={365} isLoggedAsPartner={isLoggedAsPartner} showGroupLineChart={showGroupLineChart}/>
                                    </div>
                                    {!loading &&
                                    <div className={isMultipleLegendRows ? 'col-12' : 'col-4'}>
                                        <GroupChartDataSelect value={this.state.groupChartSelectedDataType}
                                                              onSelect={this.selectGroupChartDataType}
                                                              statisticType={this.state.selectedTab}/>
                                        <ChartLegend type="doughnut" lines={chartData && chartData.chartData ? chartData.chartData.map(data => data.lineName) : []}
                                                     displayedLines={displayedGroupChartLines}
                                                     toggleChartLegend={this.toggleGroupChartLegend}/>
                                    </div>
                                    }
                                </div>
                                :
                                !loading && <NoContentMessage/>
                        }
                    </>
                }
                {
                    showGroupDonutChart &&
                    <>
                        {
                            (chartData && chartData.labels && chartData.labels.length > 0) || loading
                                ?
                                <div className="row">
                                    <div className={isMobileDisplay || loading ? 'col-12' : 'col-8'} style={{paddingTop: '10px'}}>
                                        <DoughnutChart{...chartData} isLoading={loading} dataType={this.state.groupChartSelectedDataType.label}
                                       />
                                    </div>
                                    {!loading &&
                                    <div className={isMobileDisplay ? 'col-12' : 'col-4'}>
                                       <GroupChartDataSelect value={this.state.groupChartSelectedDataType}
                                                             onSelect={this.selectGroupChartDataType}
                                                             statisticType={this.state.selectedTab}
                                                             donutChart={showGroupDonutChart}/>
                                        <ChartLegend type="doughnut" lines={chartData.allLabels}
                                                     displayedLines={displayedGroupChartLines}
                                                     toggleChartLegend={this.toggleGroupChartLegend}/>
                                    </div>
                                    }
                                </div>
                                :
                                !loading && <NoContentMessage/>
                        }
                    </>
                }
            </>
        );

    };

    renderTable = () => {
        const {loading} = this.props;
        return (
            (this.state.showTable && this.state.tableBtnSelected && this.state.filteredTableData) ?
            <>
                    <StatisticTable columns={this.getTableColumns()}
                                    isLoading={loading} expandedRows={this.state.expandedRows}
                                    tableHeaderGroupName={this.state.tableHeaderGroupName} currentSorting={this.state.sorting}
                                    onSort={this.onChangeTableSorting} toggleRowsExpansion={this.toggleRowsExpansion}
                                    toggleExpandTableSettings={this.toggleExpandTableSettings}
                                    isTableSettingsExpanded={this.state.isTableSettingsExpanded}
                                    hiddenColumns={this.state.hiddenColumns}
                                    showOrHideTableColumn={this.showOrHideTableColumn}
                                    showDetails={this.showDetails}
                                    tableData={this.state.filteredTableData} statisticType={this.state.selectedTab}
                                    filters={this.state.filters} handleLtvInterval={this.handleLtvInterval}
                                    getXlsxReportData={this.getXlsxData}
                    />
                    <StatisticDetailsModal isLoading={this.props.loadingDetailedStatistic} data={this.props.detailedStatisticData}
                                                totalRows={this.props.detailedStatisticData && this.props.detailedStatisticData.length > 0 ? this.state.totalDetailedStatisticRows : 0}
                                                rowsLimit={detailedStatisticRowsLimit}
                                                isOpen={this.state.isDetailsModalOpen} toggleDetailsModalOpen={this.toggleDetailsModalOpen}
                                                statisticType={this.state.detailedStatisticColumn}
                                                exportToCsv={this.exportToCsv}/>
            </> :
                <div style={{height: "30px"}}/>
        );
    };

    renderContent = () => {
        const tabs = this.props.isLoggedAsPartner
            ? partnerStatisticPageTabs
            : isMobileScreen()
                ?  managerStatisticMobilePageTabs
                :  managerStatisticDesktopPageTabs;
        return (
            <PageContent
                tabs={tabs}
                selectedTabName = {this.state.selectedTab}
                onSelectTab = {this.selectTab}
                displayContentHeader
                displayFiltersBtn
                displayXlsxBtn
                xlsxData = {this.state.xlsxReportData}
                displayTableSettingsBtn
                onClickTableSettingsBtn={this.toggleExpandTableSettings}
                isTableSettingsExpanded={this.state.isTableSettingsExpanded}
                displaySearchInput
                onChangeSearchInput = {this.changeSearchValue}
                searchInputAmount = {this.state.filteredTableData?.length}
                beforeSearchInputAmount = {this.props.tableData?.length}
            contentEl={
                <>
                    {this.renderChart()}
                    {this.renderTable()}
                 </>
            }
            filtersEl={
                <StatisticFiltersCard pageState={this.state}

                                      selectTab={this.selectTab}
                                      clearFilters={this.clearFilters}
                                      selectFromDate={this.selectFromDate}
                                      selectToDate={this.selectToDate}
                                      selectPeriod={this.selectPeriod}
                                      selectGroup={this.selectGroup}
                                      handleChangeFilterData={this.handleChangeFilterData}
                                      changeSearchValue={this.changeSearchValue}
                                      selectPreset={this.selectPreset}
                                      deletePreset={this.deletePreset}
                                      saveFiltersPreset={this.saveFiltersPreset}
                                      createStatisticReport={this.createStatisticReport}
                                      buildChart={this.buildChart}
                                      toggleGroupsData={this.toggleGroupsData}
                />
            }
            />
        )
    };

    render() {

        return (
            <BasePage pageName="Статистика"
                      content={this.renderContent()}
                      />
        );
    }
}

const sortTableData = (tableData, sorting, level, groups) => {
    let columnName;
    let orderBy;
    let direction;
    if (tableData && sorting && sorting.column) {
        tableData = tableData.slice(0);
        const dateGroupType = groups[level] ? isDateGroupType(groups[level].value) : isDateGroupData(tableData[0]?.groupData);
        if (sorting.column === "groupName") {
            if (level === 0) {
                columnName = sorting.column;
                orderBy = sorting.direction;
            } else if (dateGroupType) {
                columnName = sorting.column;
                orderBy = ASC;
            } else {
                columnName = SUBSCRIPTIONS;
                orderBy = DESC;
            }
        } else {
            if (dateGroupType) {
                columnName = "groupName";
                orderBy = ASC;
            } else {
                columnName = sorting.column;
                orderBy = sorting.direction;
            }
        }
        direction = orderBy === DESC ? -1 : 1;
        tableData = sort(tableData, columnName, direction);
        const nextLevel = ++level;
        tableData.forEach(group => {
            if (group.nestedData) {
                group.nestedData = sortTableData(group.nestedData, sorting, nextLevel, groups);
            }
        });

    }
    return tableData;
};

const sort = (data, columnName, direction) => {
    return data.sort((a, b) => {
        if (a[columnName] === b[columnName]) {
            return 0;
        }
        return a[columnName] > b[columnName] ? direction : direction * -1;
    });
};

function mapStateToProps(state) {
    return {
        tableColumns: state.statisticReducer.tableColumns,
        lineChartColumns: state.statisticReducer.lineChartColumns,
        tableData: state.statisticReducer.tableData,
        loading: state.statisticReducer.loading,
        chartData: state.statisticReducer.chartData,
        columnsPreset: state.preset.statisticColumnsPreset,
        filtersPresets: state.preset.statisticFiltersPresets,
        allOperators: state.dictionaries.groupedOperators,
        detailedStatisticData: state.statisticReducer.detailedStatisticData,
        loadingDetailedStatistic: state.statisticReducer.loadingDetailedStatistic,
        isLoggedAsPartner: state.auth.isPartner || state.auth.isAdminAsPartner,
        activeOperators: state.dictionaries.activeOperators,
        activeCountries: state.dictionaries.activeCountries,
        ltvServiceTypes: state.ltvServiceTypesReducer.ltvServiceTypes,
        rosLines: state.rosLinesReducer.rosLines,
        isFilterBlockOpen: state.navigationReducer.isFilterBlockOpenByMouse || state.navigationReducer.isFilterBlockOpenByBtn
    }
}

export default connect(mapStateToProps,
    {
        savePreset,
        deleteSelectedPreset,
        getStatistic,
        clearStatisticData,
        showWarning,
        activeOffers,
        loadUserPresets,
        loadOperatorTypes,
        loadActiveOperators,
        loadGroupedOperators,
        loadPartnersFilterData,
        loadServiceGroups,
        loadLtvServiceTypes,
        loadRosLines,
        getDetailedStatistic,
        exportDetailedStatistic,
        toggleOpenFilterBlock
    })(StatisticPage);