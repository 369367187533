import React, {useEffect, useRef, useState} from 'react';
import Spinner from "../../../common/Spinner";
import StatisticTableHeaderRow from "./StatisticTableHeaderRow";
import StatisticTableTotalFooterRow from "./StatisticTableTotalFooterRow";
import StatisticTableBody from "./StatisticTableBody";
import NoContentMessage from "../../../common/NoContentMessage";
import {
    calculateLtvSubtotal,
    getCR,
    getPercentageOfUnsubscribes,
    sumTableData,
    xlsxCellNumFormat,
    xlsxColumnsFormatter
} from "../../../../utils/statisticUtils";
import TableColumnsSettings from "./TableColumnsSettings";
import LtvAnalysisSummary from "./LtvAnalysisSummary";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import {deductTimezoneOffset} from "../../../../utils/dateUtils";
import {
    CVR,
    PERCENTAGE_OF_UNSUBSCRIBES, MANAGER_LTV_ANALYSIS, EPC, EPC_WITH_TB,
} from "../../../../utils/constants";
import useResizeObserver from "../../../../hooks/useResizeObserver";
import {isMobileHorizontalScreen, isMobileVerticalScreen} from "../../../../utils/displayUtils";

const NUMBER_OF_ROWS_TO_RENDER = 50;

export default function StatisticTable({columns, tableData, isLoading, tableHeaderGroupName, currentSorting, onSort, toggleRowsExpansion,
                            isTableSettingsExpanded, toggleExpandTableSettings, hiddenColumns, showOrHideTableColumn,
                            showDetails, expandedRows, statisticType, filters, handleLtvInterval, getXlsxReportData}) {
    const tableRef = useRef();
    const ltvRef = useRef();
    useOutsideClick(tableRef, () => isTableSettingsExpanded && toggleExpandTableSettings(false));

    const scrollableObserver = useResizeObserver(document.getElementById("scrollable"));
    const [lastIndex, setLastIndex] = useState(0);
    const [tableFooterData, setTableFooterData] = useState([]);
    const [renderedData, setRenderedData] = useState([]);
    const [tableBodyData, setTableBodyData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [rootRows, setRootRows] = useState([]);
    const [emptyColumns, setEmptyColumns] = useState([]);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === "Shift") {
                const tableEl = document.getElementById("stat_table");
                if (!tableEl) {
                    return;
                }
                const classList = tableEl.classList;
                if (e.shiftKey) {
                    classList.remove("userselecttext");
                } else {
                    classList.add("userselecttext");
                }
            }
        };
        window.addEventListener("keydown", handleKeyPress);
        window.addEventListener("keyup", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
            window.removeEventListener("keyup", handleKeyPress);
        };
    }, []);

    useEffect(() => {
        const tableFooterData = getFooterData();
        setTableFooterData(tableFooterData);

        const tableBodyData = getBodyData(tableData, expandedRows, 0);
        setTableBodyData(tableBodyData);

        const emptyColumns = getEmptyColumns();
        setEmptyColumns(emptyColumns);

        const endIdx = (lastIndex + NUMBER_OF_ROWS_TO_RENDER) < tableBodyData.length ? (lastIndex + NUMBER_OF_ROWS_TO_RENDER) : tableBodyData.length;
        changeRenderedData(endIdx, tableBodyData);
        const rootRows = tableData && tableData.map(row => row.rowId);
        setRootRows(rootRows);
    }, [tableData, expandedRows, selectedRows, filters.buyoutRate, filters.financeFee, handleLtvInterval()]);

    useEffect(() => {
        const tableFooterData = getFooterData();
        setTableFooterData(tableFooterData);

        const emptyColumns = getEmptyColumns();
        setEmptyColumns(emptyColumns);

    }, [renderedData]);

    useEffect(() => {
        if (!tableBodyData) {
            return;
        }
        const dataset = xlsxDataSet();
        const date = deductTimezoneOffset(new Date());
        const filename = "am-stat-" + date.getFullYear() + (date.getMonth() + 1) + date.getDate() + "-" + date.getHours() + date.getMinutes();
        getXlsxReportData({filename:filename, dataset:dataset});
    }, [tableBodyData]);

    const getStatisticTableHeight = () => {
        let statisticTableHeight = scrollableObserver?.height - ltvRef?.current?.offsetHeight;
        if (!isMobileVerticalScreen() && !isMobileHorizontalScreen()) {
            statisticTableHeight -= 30;
        }
        return statisticTableHeight + "px"
    };

    const getBodyData = (tableData, expandedRows, level) => {
        let rows = [];
        if (!level) {
            level = 0;
        }
        if (tableData && tableData.length > 0) {
            tableData.forEach(function (rowData) {
                let openedRows = expandedRows;
                let row = rowData;
                row.level = level;
                rows.push(row);
                if (rowData.nestedData && rowData.nestedData.length > 0) {
                    row.isGroupRow = true;
                    if (openedRows.includes(row.rowId)) {
                        row.isOpen = true;
                        Array.prototype.push.apply(rows, getBodyData(rowData.nestedData, openedRows, level + 1));
                    } else {
                        row.isOpen = false;
                    }
                }
            });
        }
        return rows;
    };

   const getFooterData = () => {
       let footerData = {};
       const filteredTableData = (selectedRows && selectedRows.length > 0)
           ? tableData.filter(item => selectedRows.includes(item.rowId))
           : tableData;
       if (!filteredTableData.length) return footerData;

       for (let column in filteredTableData[0]){
           footerData[column] = sumTableData(filteredTableData, column);
       }
       footerData[CVR] = getCR(footerData, statisticType);
       footerData[PERCENTAGE_OF_UNSUBSCRIBES] = getPercentageOfUnsubscribes(footerData, statisticType);

       if (statisticType === MANAGER_LTV_ANALYSIS) {
           calculateLtvSubtotal(footerData, filteredTableData, filters);
       }
       return footerData;
    };

   const getEmptyColumns = () => {
       let emptyColumns = {};
       const allColumns = [...columns.map(column => column.name)] //, ...hiddenColumns];
       const filteredTableData = (selectedRows && selectedRows.length > 0)
           ? tableData.filter(item => selectedRows.includes(item.rowId))
           : tableData;

       allColumns.forEach(column => {
           emptyColumns[column] = isEmptyColumn(filteredTableData, column);
       });
       return emptyColumns;
   };

   const isEmptyColumn = (tableData, columnName) => {
       if (tableData.find(row => row[columnName] || (row.nestedData && row.isOpen && !isEmptyColumn(row.nestedData, columnName)))) {
           return false;
       }
       return true;
   };
    const onTableScroll = (e) => {
        const srcElement = e.target;
        if (srcElement.clientHeight + srcElement.scrollTop < (srcElement.scrollHeight - 205)) {
            return;
        }
        const endIdx = (tableBodyData.length - renderedData.length) > NUMBER_OF_ROWS_TO_RENDER ? (renderedData.length + NUMBER_OF_ROWS_TO_RENDER) : tableBodyData.length;
        changeRenderedData(endIdx, tableBodyData);
    };

    const onExpandRow = (rowId, dataIndex) => {
        setLastIndex((dataIndex));
        toggleRowsExpansion(rowId);
    };

    const changeRenderedData = (lastIndex, bodyRowsData) => {
        setLastIndex(lastIndex);
        const renderedData = bodyRowsData.slice(0, lastIndex);
        setRenderedData(renderedData);
    };

    const xlsxColumns = () => {
        const tableData = tableBodyData && tableBodyData.length > 60000 ? [] : tableBodyData;
        let maxWidth = Math.max(...tableData.map(item => item.groupName?.length));

        let xlsxColumns = [...[{label: tableHeaderGroupName}], ...columns];
        xlsxColumns = xlsxColumns.map(column =>  {return {title: column.label, width: {wch: 12}, height: {wpx: 14}}});
        xlsxColumns[0].width = {wch: maxWidth + 5};
        return xlsxColumns;
    };

    const xlsxData = () => {
        const tableData = tableBodyData && tableBodyData.length > 60000 ? [] : tableBodyData;
        const resData = [...tableData, ...[tableFooterData]];
        return resData.map((row, rowIdx) => {
            const xlsxRow = [];
            columns && columns.forEach((column, colIdx) => {

                if (colIdx === 0) {
                    if (rowIdx === resData.length - 1) {
                        xlsxRow.push({value: "Итого: ", style: {font: {sz: "11", bold: true}, alignment: {horizontal: "left"}}});
                    } else {
                        xlsxRow.push({value: row.groupName, style: {font: {sz: "11"}, alignment: {horizontal: "center"}}});
                    }
                }
                if (rowIdx === resData.length - 1) {
                    xlsxRow.push({value: xlsxColumnsFormatter[column.name]?.(row[column.name], row), style: {font: {sz: "11", bold: true}, alignment: {horizontal: "right"},
                            numFmt: xlsxCellNumFormat[column.name]?.(row[column.name])}});
                } else {
                    xlsxRow.push({value: xlsxColumnsFormatter[column.name]?.(row[column.name], row), style: {font: {sz: "11"}, alignment: {horizontal: "right"},
                            numFmt: xlsxCellNumFormat[column.name]?.(row[column.name])}});
                }

            });
            return xlsxRow;
        })
    };

    const xlsxDataSet = () => {
        return [{columns: xlsxColumns(), data: xlsxData()}]
    };

    function renderTable() {
        return (
                <div ref={tableRef} style={{height: "inherit"}}>
                        <div  ref={ltvRef} style={{overflowX: "auto"}}>
                        <LtvAnalysisSummary data={tableFooterData}
                                            hiddenColumns={hiddenColumns}
                                            handleLtvInterval={handleLtvInterval}
                       />
                        </div>
                        <TableColumnsSettings isTableSettingsExpanded={!!isTableSettingsExpanded}
                                              hiddenColumns={hiddenColumns} emptyColumns={emptyColumns}
                                              showOrHideTableColumns={showOrHideTableColumn} handleLtvInterval={handleLtvInterval}/>

                        <div className="responsive-block statistic_table_wrapper" id="fullWidth" onScroll={onTableScroll} style={{height: getStatisticTableHeight()}}>
                        <table className="table table-bordered nowrap statistic_table sticky-header sticky-footer userselecttext" id="stat_table">
                            <StatisticTableHeaderRow columns={columns.filter(column => !emptyColumns[column.name])} groupName={tableHeaderGroupName} currentSorting={currentSorting} onSort={onSort}/>
                            <StatisticTableBody columns={columns.filter(column => !emptyColumns[column.name])} data={renderedData} onExpandRow={onExpandRow} showDetails={showDetails}
                                                selectedRows={selectedRows} setSelectedRows={setSelectedRows} rootRows={rootRows}/>
                            <StatisticTableTotalFooterRow columns={columns.filter(column => !emptyColumns[column.name])} data={tableFooterData}/>
                        </table>
                    </div>
                </div>
        );
    }

    function renderSpinner() {
        return (
            <Spinner/>
        );
    }

    function renderEmpty() {
        return (<NoContentMessage style={{padding: 0}}/>);
    }

    return isLoading ?
        renderSpinner() :
        (tableData && tableData.length > 0) ?
            renderTable() :
            renderEmpty();
}
