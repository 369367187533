import React, {useEffect, useState} from "react";
import {Popover} from "@material-ui/core";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {
    deductTimezoneOffset,
    getIsoLocalString,
    getMaxDate,
    getServerCurrentDay
} from "../../../../utils/dateUtils";
import DatePicker from "../../../common/pickers/DatePicker";
import MaterialUIMultiSelect from "../../../common/selects/MaterialUIMultiSelect";
import {PERCENT, RU, SUBSCRIBE_1, VALUE, VALUE_RUB} from "../../../../utils/constants";
import {convertFixedIncomeAfterTypeChange, getFixedIncomeScale} from "../../../../utils/incomeUtils";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField/TextField";
import {
    isNumberStringWithSpecifiedNumberOfDecimalPlaces,
    isNumberStringWithTwoDecimalPlaces
} from "../../../../utils/validationUtils";

import {deleteIncome, loadWithChildrenOfferIncomes, saveIncomes} from "../../../../actions/incomesActions";
import CircleButtonsGroup from "../../../common/buttons/CircleButtonsGroup";
import IosCheckbox from "../../../common/checkboxes/IosCheckbox";
import {NEW_OFFER_IDS} from "../../../../utils/offerUtils";

const fieldValidationPredicates = {
    income: (value, numberOfDecimalPlaces) => isNumberStringWithSpecifiedNumberOfDecimalPlaces(value, numberOfDecimalPlaces),
    incomeInPercent: (value) => isNumberStringWithTwoDecimalPlaces(value),
};


function OfferEditIncomePopover({offer, parentOffer, income, button, label, tooltip, currencyRate, partnersFilterData, saveIncomes, loadWithChildrenOfferIncomes}) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [partners, setPartners] = useState([]);

    const [errorFields, setErrorFields] = useState([]);
    const [editableIncome, setEditableIncome] = useState({});

    useEffect(() => {
        if (!open) {
            return;
        }
        const editableIncome = income
            ? {...income, partnerIds: (income.partnerId ? [income.partnerId] : null)}
            : {offerId: offer.id, billingType: offer.billingType}
        ;
        setEditableIncome(editableIncome);
    }, [open]);


    useEffect(() => {
        const partnersData = partnersFilterData.reduce((prev, curr) => {
            return [...prev, ...curr.options]
        }, []);
        partnersData.sort((a, b) => a.partnerName.localeCompare(b.partnerName));
        setPartners(partnersData);
    }, [partnersFilterData]);


    const currentDate = getIsoLocalString(getServerCurrentDay());

    const paymentTypesMapping = {
        VALUE: "В нац. валюте",
        VALUE_RUB: "В рублях",
        PERCENT: "% от EUP без ндс"
    };

    const paymentTypeDropDownWithoutRub = [
        {label: paymentTypesMapping[VALUE], value: VALUE},
        {label: paymentTypesMapping[PERCENT], value: PERCENT},
    ];

    const paymentTypeDropDown = [
        {label: paymentTypesMapping[VALUE], value: VALUE},
        {label: paymentTypesMapping[VALUE_RUB], value: VALUE_RUB},
        {label: paymentTypesMapping[PERCENT], value: PERCENT},
    ];

    const paymentTypeDropdownData = (offer.countryType === RU || offer.billingType !== SUBSCRIBE_1) ? paymentTypeDropDownWithoutRub : paymentTypeDropDown;
    const hasOfferIncomeId = editableIncome && editableIncome.offerIncomeId;
    const isIncomeDisabled = hasOfferIncomeId && editableIncome.fromDate < currentDate;

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectIncomeType = (type) => {
        const errors = errorFields.filter(field => field !== "incomeType");
        const oldIncomeType = editableIncome.incomeType;
        const newIncomeType = type ? type.value : null;
        setEditableIncome({
            ...editableIncome,
            income: convertFixedIncomeAfterTypeChange(oldIncomeType, newIncomeType, editableIncome.income, editableIncome.incomeInPercent, offer.eup, currencyRate),
            incomeType: newIncomeType,
            incomeHasChanges: true,
            newIncome: NEW_OFFER_IDS.includes(offer.offerId) && !editableIncome.clonedIncome,

        }, errors);
    };

    const selectPartners = (partners) => {
        setEditableIncome({
            ...editableIncome,
            partnerIds: partners ? partners.map(partner => partner.id) : null,
            partners: partners,
            offerIncomeId: null,
            incomeHasChanges: true,
            newIncome: NEW_OFFER_IDS.includes(offer.offerId) && !editableIncome.clonedIncome,
        });
    };

    const handleIncomeTyping = (value, fieldName) => {
        if (isNaN(value)) {
            return;
        }
        let newErrorFields = [];
        const scale = getFixedIncomeScale(editableIncome.incomeType, currencyRate);
        if (!fieldValidationPredicates[fieldName](value, scale)) {
            if (!errorFields.includes(fieldName)) {
                newErrorFields = errorFields.slice(0);
                newErrorFields.push(fieldName);
            }
        } else {
            newErrorFields = errorFields.filter(field => field !== fieldName);
        }
        const currRate = editableIncome.incomeType === VALUE_RUB ? currencyRate : 1;
        setEditableIncome({
            ...editableIncome,
            income: fieldName === "income"
                ? value
                : value !== "" && parentOffer.eup && parentOffer.eup != 0
                    ?
                    (parseFloat(value) * 0.01 * parentOffer.eup * currRate).toFixed(scale).toLocaleString("ru-RU")
                    : "",
            incomeInPercent: fieldName === "incomeInPercent"
                ? value
                : value !== "" && editableIncome.incomeType !== '' && parentOffer.eup && parentOffer.eup != 0
                    ? (parseFloat(value) * 100 / (parentOffer.eup * currRate)).toFixed(2).toLocaleString("ru-RU")
                    : "",
            incomeHasChanges: true,
            newIncome: NEW_OFFER_IDS.includes(offer.offerId) && !editableIncome.clonedIncome
        });
        setErrorFields(newErrorFields);
    };

    const toggleChecked = (chekboxName) => {
        setEditableIncome({
            ...editableIncome,
            [chekboxName]: !editableIncome[chekboxName]
        });
    };


    const selectDate = (fieldName, date) => {
        let {fromDate, toDate} = editableIncome;
        let errors = errorFields.slice(0);
        if (fieldName === "fromDate") {
            fromDate = getIsoLocalString(date);
            errors = errors.filter(field => field !== "fromDate");
        } else if (fieldName === "toDate") {
            toDate = getIsoLocalString(date);
            errors = errors.filter(field => field !== "toDate");
        }
        if (fromDate && toDate && fromDate > toDate) {
            let errors = errorFields.slice(0);
            errors.push("toDate");
        }
        setEditableIncome({
            ...editableIncome,
            [fieldName]: getIsoLocalString(date),
            incomeHasChanges: true,
            newIncome: NEW_OFFER_IDS.includes(offer.offerId) && !editableIncome.clonedIncome
        });
        setErrorFields(errors);
    };

    const saveOrUpdateIncome = () => {
        if (!editableIncome.incomeHasChanges || isSavingDisabled()) {
            return;
        }
        if (!editableIncome.offerId) {
            alert("Сохраните созданный оффер");
        } else {
            const incomesData = {
                offerId: editableIncome.offerId,
                offerIncomeId: editableIncome.offerIncomeId,
                partnerIds: editableIncome.partnerIds && editableIncome.partnerIds.length > 0 ? editableIncome.partnerIds : null,
                income: editableIncome.incomeType === PERCENT ? editableIncome.incomeInPercent.replace(",", ".") : editableIncome.income.replace(",", "."),
                incomeType: editableIncome.incomeType,
                from: editableIncome.fromDate,
                to: editableIncome.toDate,
            };

            const body = incomesData.partnerIds ? incomesData.partnerIds.map(partnerId => {return {...incomesData, partnerId: partnerId}}) : [{...incomesData, partnerId: null}];
            saveIncomes(body, !!editableIncome.needToNotifyPartners, !!editableIncome.needToRecalculateStatistic, () => afterSaveIncomeCallback());
        }
    };

    const afterSaveIncomeCallback = () => {
        loadWithChildrenOfferIncomes([parentOffer.offerId]);
        handleClose();
    };

    const isSavingDisabled = () => {
        return !editableIncome || (editableIncome.offerIncomeId && editableIncome.toDate && editableIncome.toDate < currentDate)
            || errorFields.length > 0 || !isEditableIncomeValid();
    };

    const isEditableIncomeValid = () => {
        const {offerIncomeId, fromDate, toDate, income, incomeInPercent, incomeType} = editableIncome;
        let isValid = true;
        let errors = [];
        if (!fromDate || (!offerIncomeId && fromDate < currentDate)) {
            errors.push("fromDate");
            isValid = false;
        }
        if (toDate && (toDate < currentDate || fromDate > toDate)) {
            errors.push("toDate");
            isValid = false;
        }
        if (!incomeType) {
            errors.push("incomeType");
            isValid = false;
        }
        if (isFixedIncomeType(incomeType) && (!income || income === "")) {
            errors.push("income");
            isValid = false;
        }
        if (incomeType === PERCENT && offer.eup != 0 && (!incomeInPercent || incomeInPercent === "")) {
            errors.push("incomeInPercent");
            isValid = false;
        }
        setErrorFields(errors);
        return isValid;
    };

    const isFixedIncomeType = (incomeType) => {
        return incomeType === VALUE || incomeType === VALUE_RUB;
    };


    return (
        <span>
            <div aria-describedby={id} onClick={handleClick} title={tooltip} style={{display: "inline"}}>
                {button}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                PaperProps={{style: {minWidth: "min(90vw, 370px)"}}}
            >
                <div style={{padding: "10px", textAlign: "center"}}>{label}</div>
                <div style={{padding: "20px"}}>
                   <MaterialUIMultiSelect
                       dropdownData={partners}
                       values={partners && editableIncome && editableIncome.partnerIds
                           ? editableIncome.partnerIds.map(partnerId => partners.find(partner => partner.id === partnerId))
                           : null}
                       onSelect={selectPartners}
                       dataField='id'
                       displayedField='partnerName'
                       isSearchable
                       isClearable={true}
                       maxChipTxtWidth={220}
                       placeholder="Общая ставка"
                       label="Партнёр"
                       isDisabled={editableIncome && editableIncome.offerIncomeId}
                   />
                   <DatePicker inputType="materialUi" pickerWrapperStyle={{top: "left"}} inputLabel="Дата начала"
                               date={editableIncome && editableIncome.fromDate && editableIncome.fromDate !== '' ? new Date(editableIncome.fromDate) : null}
                               autoClose
                               disabled={!!(editableIncome && editableIncome.offerIncomeId && !editableIncome.clonedIncome)}
                               minDate={deductTimezoneOffset(new Date())}
                               hasError={errorFields.includes("fromDate")}
                               onSelectDate={(date) => selectDate("fromDate", date)}/>
                   <DatePicker inputType="materialUi" pickerWrapperStyle={{top: "auto"}} inputLabel="Дата окончания"
                               date={editableIncome && editableIncome.toDate && editableIncome.toDate !== '' ? new Date(editableIncome.toDate) : null}
                               autoClose
                               disabled={!!(editableIncome?.offerIncomeId && editableIncome?.toDate && editableIncome.toDate < currentDate)}
                               minDate={getMaxDate([deductTimezoneOffset(new Date()), editableIncome.fromDate && new Date(editableIncome.fromDate)])}
                               hasError={errorFields.includes("toDate")}
                               onSelectDate={(date) => selectDate("toDate", date)}/>
                    <MaterialUISelect
                        label="Тип выплаты"
                        dropdownData={paymentTypeDropdownData}
                        selectedValue={editableIncome && paymentTypeDropdownData.find(value => value.value === editableIncome.incomeType)}
                        isDisabled={isIncomeDisabled}
                        onSelectCallbackFunction={selectIncomeType}
                        isClearable={false}/>
                    <TextField label="Ставка фикс"
                               name="income"
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={(e) => handleIncomeTyping(e.target.value, "income")}
                               value={editableIncome?.income ? editableIncome.income.replace(",", ".") : ""}
                               disabled={isIncomeDisabled}
                               style={{marginTop: '15px', minWidth: '100%'}}
                    />
                    <TextField label="Ставка %"
                               name="incomeInPercent"
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={(e) => handleIncomeTyping(e.target.value, "incomeInPercent")}
                               value={editableIncome?.incomeInPercent ? editableIncome.incomeInPercent : ""}
                               disabled={isIncomeDisabled}
                               style={{marginTop: '15px', minWidth: '100%'}}
                    />

                    {offer?.id && !NEW_OFFER_IDS.includes(offer?.id) && <>
                         <div className="form-group edit-offer">
                            <IosCheckbox label="Оповестить партнера об изменении ставки"
                                         labelClassName="float-left"
                                         isChecked={editableIncome.needToNotifyPartners}
                                         onClickFunction={() => toggleChecked("needToNotifyPartners")}/>

                        </div>
                         <div className="form-group edit-offer">
                             <IosCheckbox label="Пересчитать статистику за сегодняшний день"
                                          labelClassName="float-left"
                                          isChecked={editableIncome.needToRecalculateStatistic}
                                          onClickFunction={() => toggleChecked("needToRecalculateStatistic")}/>
                          </div>
                     </>
                        }
                    <CircleButtonsGroup buttons={
                        [
                            {
                                isSelected: false,
                                title: "Сохранить",
                                iconClassName: "ti ti-check stat-icon",
                                confirmMessage: "Сохранить?",
                                onClick: saveOrUpdateIncome
                            },
                            {
                                isSelected: false,
                                title: "Отмена",
                                iconClassName: "ti ti-close stat-icon",
                                onClick: handleClose
                            },
                        ]
                    }/>
                </div>
            </Popover>
        </span>
    )
};

const mapStateToProps = (state) => {
    return {
        partnersFilterData: state.managerPartnersReducer.partnersFilterData,
    }
};

export default connect(mapStateToProps, {
    saveIncomes,
    deleteIncome,
    loadWithChildrenOfferIncomes
})(OfferEditIncomePopover);
