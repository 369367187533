
/*AUTHENTICATION*/
export const CHECKING_AUTHENTICATION = "CHECK_AUTHENTICATION";
export const USER_AUTHENTICATED = "USER_AUTHENTICATED";
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_AS_PARTNER = "LOGIN_AS_PARTNER";
export const LOGOUT_FROM_PARTNER = "LOGOUT_FROM_PARTNER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_CONFIRMED = "REGISTER_CONFIRMED";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const CHECK_RESTORE_PASSWORD_CODE = "CHECK_RESTORE_PASSWORD_CODE";
export const CONFIRMATION_EMAIL_RESENT = "CONFIRMATION_EMAIL_RESENT";


/*DASHBOARD*/
/*ACTIVE_OFFERS*/
export const DASHBOARD_DYNAMIC_LOADING = "DASHBOARD_DYNAMIC_LOADING";
export const DASHBOARD_DYNAMIC_LOADED = "DASHBOARD_DYNAMIC_LOADED";
export const TOGGLE_OFFER_SWITCH = "TOGGLE_OFFER_SWITCH";
export const TOGGLE_ALL_OFFERS_SWITCH = "TOGGLE_ALL_OFFERS_SWITCH";
/*CHART_DATA*/
export const DASHBOARD_CHART_DATA_LOADING = "DASHBOARD_CHART_DATA_LOADING";
export const DASHBOARD_CHART_DATA_LOADED = "DASHBOARD_CHART_DATA_LOADED";

/*PARTNER_BALANCE*/
export const AVAILABLE_PARTNER_BALANCE_LOADING = "AVAILABLE_PARTNER_BALANCE_LOADING";
export const AVAILABLE_PARTNER_BALANCE_LOADED = "AVAILABLE_PARTNER_BALANCE_LOADED";
export const PARTNER_BALANCE_LOADING = "PARTNER_BALANCE_LOADING";
export const PARTNER_BALANCE_LOADED = "PARTNER_BALANCE_LOADED";
export const MANAGER_PARTNER_BALANCE_DATA_LOADING = "MANAGER_PARTNER_BALANCE_DATA_LOADING";
export const MANAGER_PARTNER_BALANCE_DATA_LOADED = "MANAGER_PARTNER_BALANCE_DATA_LOADED";

/*STATISTIC*/
export const STATISTIC_DATA_LOADING = "STATISTIC_DATA_LOADING";
export const STATISTIC_DATA_LOADED = "STATISTIC_DATA_LOADED";
export const CLEAR_STATISTIC_DATA = "CLEAR_STATISTIC_DATA";
export const DETAILED_STATISTIC_LOADING = "DETAILED_STATISTIC_LOADING";
export const DETAILED_STATISTIC_LOADED = "DETAILED_STATISTIC_LOADED";
export const DETAILED_STATISTIC_DOWNLOADING = "DETAILED_STATISTIC_DOWNLOADING";
export const DETAILED_STATISTIC_DOWNLOADED = "DETAILED_STATISTIC_DOWNLOADED";
export const REFERRER_STATISTIC_DATA_LOADING = "REFERRER_STATISTIC_DATA_LOADING";
export const REFERRER_STATISTIC_DATA_LOADED = "REFERRER_STATISTIC_DATA_LOADED";

export const BUILD_MAIN_REPORT_CHART = "BUILD_MAIN_REPORT_CHART";
export const BUILD_MAIN_REPORT_TABLE = "BUILD_MAIN_REPORT_TABLE";

export const SAVE_USER_PRESET = "SAVE_USER_PRESET";
export const DELETE_USER_PRESET = "DELETE_USER_PRESET";
export const STATISTIC_TABLE_ACTIVE_OFFERS_LOADING = "STATISTIC_TABLE_ACTIVE_OFFERS_LOADING";
export const STATISTIC_TABLE_ACTIVE_OFFERS_LOADED = "STATISTIC_TABLE_ACTIVE_OFFERS_LOADED";


/*USER*/
export const USER_PRESETS_LOADING = "USER_PRESETS_LOADING";
export const USER_PRESETS_LOADED = "USER_PRESETS_LOADED";
export const USER_DATA_LOADING = "USER_DATA_LOADING";
export const USER_DATA_LOADED = "USER_DATA_LOADED";
export const SAVE_MANAGER_PROFILE = "SAVE_MANAGER_PROFILE";
export const SAVE_PARTNER_PROFILE = "SAVE_PARTNER_PROFILE";
export const USER_REFERRAL_ID_LOADED = "USER_REFERRAL_ID_LOADED";

/*ERRORS*/
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

/*SUCCESS*/
export const GET_SUCCESS = 'GET_SUCCESS';
export const GET_SEMI_SUCCESS = 'GET_SEMI_SUCCESS';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';

/*WARNING_MESSAGES*/
export const SHOW_WARNING_MESSAGE = 'SHOW_WARNING_MESSAGE';
export const CLEAR_WARNING_MESSAGE = 'CLEAR_WARNING_MESSAGE';
export const CONFIRM_ACTION = 'CONFIRM_ACTION';

/*DICTIONARIES*/
export const DICTIONARY_OPERATOR_TYPES_LOADING = 'DICTIONARY_OPERATOR_TYPES_LOADING';
export const DICTIONARY_OPERATOR_TYPES_LOADED = 'DICTIONARY_OPERATOR_TYPES_LOADED';
export const DICTIONARY_CATEGORIES_LOADING = 'DICTIONARY_CATEGORIES_LOADING';
export const DICTIONARY_CATEGORIES_LOADED = 'DICTIONARY_CATEGORIES_LOADED';
export const DICTIONARY_GROUPED_OPERATORS_LOADING = 'DICTIONARY_GROUPED_OPERATORS_LOADING';
export const DICTIONARY_GROUPED_OPERATORS_LOADED = 'DICTIONARY_GROUPED_OPERATORS_LOADED';
export const DICTIONARY_ACTIVE_OPERATORS_LOADING = 'DICTIONARY_ACTIVE_OPERATORS_LOADING';
export const DICTIONARY_ACTIVE_OPERATORS_LOADED = 'DICTIONARY_ACTIVE_OPERATORS_LOADED';

/*PARTNER_OFFERS*/
export const PARTNER_OFFERS_LOADING = 'PARTNER_OFFERS_LOADING';
export const PARTNER_OFFERS_LOADED = 'PARTNER_OFFERS_LOADED';
export const PARTNER_OFFER_ITEM_LOADING = 'PARTNER_OFFER_ITEM_LOADING';
export const PARTNER_OFFER_ITEM_LOADED = 'PARTNER_OFFER_ITEM_LOADED';
export const CHANGE_PARTNER_OFFERS_BILLING_TYPE_FILTER = 'CHANGE_PARTNER_OFFERS_BILLING_TYPE_FILTER';
export const CHANGE_PARTNER_OFFERS_OPERATOR_FILTER = 'CHANGE_PARTNER_OFFERS_OPERATOR_FILTER';
export const CHANGE_PARTNER_OFFERS_COUNTRY_FILTER = 'CHANGE_PARTNER_OFFERS_COUNTRY_FILTER';
export const CHANGE_PARTNER_OFFERS_CATEGORY_FILTER = 'CHANGE_PARTNER_OFFERS_CATEGORY_FILTER';
export const CHANGE_PARTNER_OFFERS_STATUS_FILTER = 'CHANGE_PARTNER_OFFERS_STATUS_FILTER';
export const CHANGE_PARTNER_OFFERS_IS_NEW_FILTER = 'CHANGE_PARTNER_OFFERS_IS_NEW_FILTER';
export const CHANGE_PARTNER_OFFERS_IS_RECOMMENDED_FILTER = 'CHANGE_PARTNER_OFFERS_IS_RECOMMENDED_FILTER';
export const CHANGE_PARTNER_OFFERS_IS_ONLY_SELECTED_FILTER = 'CHANGE_PARTNER_OFFERS_IS_ONLY_SELECTED_FILTER';
export const SEARCH_FROM_ALL_PARTNER_OFFERS = 'SEARCH_FROM_ALL_PARTNER_OFFERS';
export const CHANGE_PARTNER_OFFERS_CONTENT_TYPE = 'CHANGE_PARTNER_OFFERS_CONTENT_TYPE';
export const SHOW_PARTNER_OFFERS_COMPONENT = 'SHOW_PARTNER_OFFERS_COMPONENT';
export const CREATE_PROMOTION_LINK = 'CREATE_PROMOTION_LINK';
export const PROMOTION_LINK_ENCRYPTING = 'PROMOTION_LINK_ENCRYPTING';
export const PROMOTION_LINK_ENCRYPTED = 'PROMOTION_LINK_ENCRYPTED';
export const PROMOTION_LINKS_LOADING = 'PROMOTION_LINKS_LOADING';
export const PROMOTION_LINKS_LOADED = 'PROMOTION_LINKS_LOADED';
export const CHANGE_PARTNER_OFFERS_TAB = 'CHANGE_PARTNER_OFFERS_TAB';


/*PARTNER_SMARTLINKS*/
export const PARTNER_SMARTLINKS_LOADING = 'PARTNER_SMARTLINKS_LOADING';
export const PARTNER_SMARTLINKS_LOADED = 'PARTNER_SMARTLINKS_LOADED';
export const CHANGE_PARTNER_SMARTLINKS_OPERATOR_FILTER = 'CHANGE_PARTNER_SMARTLINKS_OPERATOR_FILTER';
export const CHANGE_PARTNER_SMARTLINKS_COUNTRY_FILTER = 'CHANGE_PARTNER_SMARTLINKS_COUNTRY_FILTER';
export const CHANGE_PARTNER_SMARTLINKS_CATEGORY_FILTER = 'CHANGE_PARTNER_SMARTLINKS_CATEGORY_FILTER';
export const SEARCH_FROM_PARTNER_SMARTLINKS = 'SEARCH_FROM_PARTNER_SMARTLINKS';
export const ADD_SMARTLINKS_FOR_CREATION = 'ADD_SMARTLINKS_FOR_CREATION';
export const SAVE_SMARTLINKS = 'SAVE_SMARTLINKS';
export const CREATE_SMARTLINKS = 'CREATE_SMARTLINKS';
export const DELETE_SMARTLINK = 'DELETE_SMARTLINK';
export const EDIT_SMARTLINK = 'EDIT_SMARTLINK';
export const PARTNER_SMARTLINK_LOADING = 'PARTNER_SMARTLINK_LOADING';
export const PARTNER_SMARTLINK_LOADED = 'PARTNER_SMARTLINK_LOADED';
export const SMARTLINK_URL_LOADED = 'SMARTLINK_URL_LOADED';
export const CHANGE_SMARTLINK_NAME = 'CHANGE_SMARTLINK_NAME';
export const EXCLUDE_OFFER_FROM_SMARTLINK = 'EXCLUDE_OFFER_FROM_SMARTLINK';
export const CHANGE_SMARTLINK_OFFER_PROBABILITY = 'CHANGE_SMARTLINK_OFFER_PROBABILITY';
export const CHANGE_PARTNER_SMARTLINK_PROJECT_FILTER = 'CHANGE_PARTNER_SMARTLINK_PROJECT_FILTER';
export const CHANGE_PARTNER_SMARTLINK_OFFERS = 'CHANGE_PARTNER_SMARTLINK_OFFERS';
export const EXCLUDE_SMARTLINK_CATEGORY_ID = 'EXCLUDE_SMARTLINK_CATEGORY_ID';


/*PARTNER_PROJECTS*/
export const PARTNER_PROJECTS_LOADING = 'PARTNER_PROJECTS_LOADING';
export const PARTNER_PROJECTS_LOADED = 'PARTNER_PROJECTS_LOADED';
export const PARTNER_PROJECTS_LOADED_WITH_NEW = 'PARTNER_PROJECTS_LOADED_WITH_NEW';
export const PARTNER_PROJECT_SAVED = 'PARTNER_PROJECT_SAVED';
export const PARTNER_PROJECT_DELETED = 'PARTNER_PROJECT_DELETED';
export const CHANGE_PARTNER_LINKS_PROJECT_FILTER = 'CHANGE_PARNER_LINKS_PROJECT_FILTER';

/*MANAGER_PARTNERS*/
export const MANAGER_PARTNERS_LOADING = 'MANAGER_PARTNERS_LOADING';
export const MANAGER_PARTNERS_LOADED = 'MANAGER_PARTNERS_LOADED';
export const MANAGER_PARTNERS_FILTER_DATA_LOADED = 'MANAGER_PARTNERS_FILTER_DATA_LOADED';
export const MANAGER_PARTNERS_APPROVE_REGISTRATION = 'MANAGER_PARTNERS_APPROVE_REGISTRATION';
export const MANAGER_PARTNERS_REJECT_REGISTRATION = 'MANAGER_PARTNERS_REJECT_REGISTRATION';
export const MANAGER_PARTNERS_ADD_OR_REMOVE_FROM_FAVORITES = 'MANAGER_PARTNERS_ADD_OR_REMOVE_FROM_FAVORITES';
export const MANAGER_PARTNERS_TOGGLE_PAYMENT_AVAILABLE = 'MANAGER_PARTNERS_TOGGLE_PAYMENT_AVAILABLE';
export const MANAGER_PARTNERS_CHANGE_LEVEL = 'MANAGER_PARTNERS_CHANGE_LEVEL';

/*PAYMENTS*/
export const PARTNER_PAYMENTS_LOADING = 'PARTNER_PAYMENTS_LOADING';
export const PARTNER_PAYMENTS_LOADED = 'PARTNER_PAYMENTS_LOADED';
export const CLEAR_PARTNER_PAYMENTS_DATA = 'CLEAR_PARTNER_PAYMENTS_DATA';
export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const MANAGER_PAYMENTS_LOADING = 'MANAGER_PAYMENTS_LOADING';
export const MANAGER_PAYMENTS_LOADED = 'MANAGER_PAYMENTS_LOADED';
export const PAYMENT_CANCELED = 'PAYMENT_CANCELED';
export const PAYMENT_APPROVED = 'PAYMENT_APPROVED';
export const SELECTED_PAYMENTS_APPROVED = 'SELECTED_PAYMENTS_APPROVED';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';

/*SERVICE_GROUPS*/
export const SERVICE_GROUPS_LOADING = 'SERVICE_GROUPS_LOADING';
export const SERVICE_GROUPS_LOADED = 'SERVICE_GROUPS_LOADED';

/*PARTNER_DOMAINS*/
export const PARTNER_DOMAINS_LOADING = 'PARTNER_DOMAINS_LOADING';
export const PARTNER_DOMAINS_LOADED = 'PARTNER_DOMAINS_LOADED';
export const PARTNER_DOMAIN_CREATING = 'PARTNER_DOMAIN_CREATING';
export const PARTNER_DOMAIN_CREATED = 'PARTNER_DOMAIN_CREATED';
export const PARTNER_DOMAIN_DELETED = 'PARTNER_DOMAIN_DELETED';
export const PARTNER_DOMAIN_MAKE_DEFAULT = 'PARTNER_DOMAIN_MAKE_DEFAULT';

/*MANAGER_OFFERS*/
export const MANAGER_OFFERS_LOADING = 'MANAGER_OFFERS_LOADING';
export const MANAGER_ACTIVE_OFFERS_LOADED = 'MANAGER_ACTIVE_OFFERS_LOADED';
export const MANAGER_INACTIVE_OFFERS_LOADED = 'MANAGER_INACTIVE_OFFERS_LOADED';
export const MANAGER_DEPRECATED_OFFERS_LOADED = 'MANAGER_DEPRECATED_OFFERS_LOADED';
export const MANAGER_ALL_OFFERS_LOADING = 'MANAGER_ALL_OFFERS_LOADING';
export const MANAGER_ALL_OFFERS_LOADED = 'MANAGER_ALL_OFFERS_LOADED';
export const MANAGER_OFFERS_MASS_UPDATED = 'MANAGER_OFFERS_MASS_UPDATED';
export const OFFER_CARD_DATA_LOADED = 'OFFER_CARD_DATA_LOADED';
export const MANAGER_OFFER_ACTIVATED = 'MANAGER_OFFER_ACTIVATED';
export const MANAGER_OFFER_DEACTIVATED = 'MANAGER_OFFER_DEACTIVATED';
export const MANAGER_OFFER_DEPRECATED = 'MANAGER_OFFER_DEPRECATED';
export const MANAGER_OFFER_DELETED = 'MANAGER_OFFER_DELETED';
export const MANAGER_OFFER_SAVED = 'MANAGER_OFFER_SAVED';
export const MANAGER_OFFER_CHILD_ADDED = 'MANAGER_OFFER_CHILD_ADDED';
export const MANAGER_OFFER_CHILD_REMOVED = 'MANAGER_OFFER_CHILD_REMOVED';
export const MANAGER_OFFER_CHILDREN_LOADED = 'MANAGER_OFFER_CHILDREN_LOADED';

/*SUBSCRIPTION_SERVICES*/
export const SUBSCRIPTION_SERVICES_LOADING = 'SUBSCRIPTION_SERVICES_LOADING';
export const SUBSCRIPTION_SERVICES_LOADED = 'SUBSCRIPTION_SERVICES_LOADED';

/*OFFER_INCOMES*/
export const OFFER_INCOMES_SAVED = 'OFFER_INCOMES_SAVED';
export const OFFER_INCOME_DELETED = 'OFFER_INCOME_DELETED';
export const OFFER_INCOMES_LOADING = 'OFFER_INCOMES_LOADING';
export const OFFER_INCOMES_LOADED = 'OFFER_INCOMES_LOADED';
export const OFFER_WITH_CHILDREN_INCOMES_LOADING = 'OFFER_WITH_CHILDREN_INCOMES_LOADING';
export const OFFER_WITH_CHILDREN_INCOMES_LOADED = 'OFFER_WITH_CHILDREN_INCOMES_LOADED';

/*RETENTION_EVENT*/
export const ADD_RETENTION_EVENT = 'ADD_RETENTION_EVENT';
export const REMOVE_RETENTION_EVENT = 'REMOVE_RETENTION_EVENT';

/*CHAT*/
export const CHAT_CREATED = 'CHAT_CREATED';
export const CHAT_LOADED = 'CHAT_LOADED';
export const CHATS_NUMBER_OF_UNREAD_MESSAGES_LOADED = 'CHATS_NUMBER_OF_UNREAD_MESSAGES_LOADED';
export const CHATS_HISTORY_LOADED = 'CHATS_HISTORY_LOADED';
export const CHATS_MESSAGES_READ = 'CHATS_MESSAGES_READ';
export const CHAT_MESSAGE_TYPING = 'CHAT_MESSAGE_TYPING';
export const TOGGLE_OPEN_CHAT = 'TOGGLE_OPEN_CHAT';
export const ADD_MESSAGE_TO_CHAT = 'ADD_MESSAGE_TO_CHAT';

/*NEWS*/
export const NEWS_LOADING = 'NEWS_LOADING';
export const NEWS_LOADED = 'NEWS_LOADED';
export const NEWS_ITEM_CARD_LOADED = 'NEWS_ITEM_CARD_LOADED';
export const NEWS_ITEM_SAVED = 'NEWS_ITEM_SAVED';
export const NEWS_ITEM_READ = 'NEWS_ITEM_READ';
export const CLEAR_EDITABLE_NEWS_ITEM = 'CLEAR_EDITABLE_NEWS_ITEM';
export const HEADER_NEWS_LOADING = 'HEADER_NEWS_LOADING';
export const HEADER_NEWS_LOADED = 'HEADER_NEWS_LOADED';
export const CLEAR_HEADER_NEWS = 'CLEAR_HEADER_NEWS';
export const BLOG_NEWS_LOADING = 'BLOG_NEWS_LOADING';
export const BLOG_NEWS_LOADED = 'BLOG_NEWS_LOADED';
export const CLEAR_NEWS = 'CLEAR_NEWS';
export const CLEAR_BLOG_ITEM = 'CLEAR_BLOG_ITEM';
export const BLOG_ITEM_LOADED = 'BLOG_ITEM_LOADED';

/*FILES*/
export const FILE_UPLOADED = 'FILE_UPLOADED';
export const FILE_DELETED = 'FILE_DELETED';
export const ALL_FILES_DATA_LOADED = 'ALL_FILES_DATA_LOADED';

/*PAGE_CONTENT*/
export const PAGE_CONTENT_LOADING = 'PAGE_CONTENT_LOADING';
export const PAGE_CONTENT_LOADED = 'PAGE_CONTENT_LOADED';
export const PAGE_CONTENT_SAVED = 'PAGE_CONTENT_SAVED';

/*IP_RANGES*/
export const IP_RANGES_LOADING = 'IP_RANGES_LOADING';
export const IP_RANGES_LOADED = 'IP_RANGES_LOADED';
export const IP_RANGES_TEMPLATE_DADA_LOADING = 'IP_RANGES_TEMPLATE_DADA_LOADING';
export const IP_RANGES_TEMPLATE_DADA_LOADED = 'IP_RANGES_TEMPLATE_DADA_LOADED';
export const IP_RANGES_UPLOADED = 'IP_RANGES_UPLOADED';

/*BLACK_LIST*/
export const BLACK_LIST_LOADING = 'BLACK_LIST_LOADING';
export const BLACK_LIST_LOADED = 'BLACK_LIST_LOADED';

/*CURRENCY_RATES*/
export const ACTUAL_CURRENCY_RATES_LOADING = 'ACTUAL_CURRENCY_RATES_LOADING';
export const ACTUAL_CURRENCY_RATES_LOADED = 'ACTUAL_CURRENCY_RATES_LOADED';

/*CAP*/
export const CAP_LOADING = 'CAP_LOADING';
export const CAP_LOADED = 'CAP_LOADED';

/*CAP_NAMES*/
export const CAP_NAMES_LOADING = 'CAP_NAMES_LOADING';
export const CAP_NAMES_LOADED = 'CAP_NAMES_LOADED';
export const CAP_NAME_ITEM_SAVED = 'CAP_NAME_ITEM_SAVED';
export const CAP_NAME_ITEM_DELETED = 'CAP_NAME_ITEM_DELETED';
export const CAP_NAMES_RELOADING = 'CAP_NAMES_RELOADING';
export const CAP_NAMES_RELOADED = 'CAP_NAMES_RELOADED';

/*ADVERTISING_NETWORKS*/
export const ALL_ADVERTISING_NETWORKS_LOADING = 'ALL_ADVERTISING_NETWORKS_LOADING';
export const ALL_ADVERTISING_NETWORKS_LOADED = 'ALL_ADVERTISING_NETWORKS_LOADED';
export const ADVERTISING_NETWORK_SAVED = 'ADVERTISING_NETWORK_SAVED';
export const ACTIVE_ADVERTISING_NETWORKS_LOADING = 'ACTIVE_ADVERTISING_NETWORKS_LOADING';
export const ACTIVE_ADVERTISING_NETWORKS_LOADED = 'ACTIVE_ADVERTISING_NETWORKS_LOADED';
export const ADVERTISING_NETWORK_BALANCE_LOADING = 'ADVERTISING_NETWORK_BALANCE_LOADING';
export const ADVERTISING_NETWORK_BALANCE_LOADED = 'ADVERTISING_NETWORK_BALANCE_LOADED';

/*LTV_SERVICE_TYPES*/
export const LTV_SERVICE_TYPES_LOADED = 'LTV_SERVICE_TYPES_LOADED';
export const LTV_SERVICE_TYPES_LOADING = 'LTV_SERVICE_TYPES_LOADING';

/*ROS_LINES*/
export const ROS_LINES_LOADING = "ROS_LINES_LOADING";
export const ROS_LINES_LOADED = "ROS_LINES_LOADED";
export const ROS_LINES_FILE_UPLOADED = "ROS_LINES_FILE_UPLOADED";

/*POSTBACK_TEST*/
export const POSTBACK_TEST_SENT = 'POSTBACK_TEST_SENT';
export const POSTBACK_TEST_SEND = 'POSTBACK_TEST_SEND';

/*POSTBACK_RESENDING*/
export const PARTNER_RULES_LOADING = "PARTNER_RULES_LOADING";
export const PARTNER_RULES_LOADED = "PARTNER_RULES_LOADED";
export const RESEND_POSTBACKS = "RESEND_POSTBACKS";
export const POSTBACK_ITEMS_LOADING = "POSTBACK_ITEMS_LOADING";
export const POSTBACK_ITEMS_LOADED = "POSTBACK_ITEMS_LOADED";
export const DELETE_RESENDING_ITEM = "DELETE_RESENDING_ITEM";

/*OFFER_BUYOUT_RULES*/
export const OFFER_BUYOUT_RULES_LOADING = "OFFER_BUYOUT_RULES_LOADING";
export const OFFER_BUYOUT_RULES_LOADED = "OFFER_BUYOUT_RULES_LOADED";
export const OFFER_BUYOUT_RULES_SAVED = "OFFER_BUYOUT_RULES_SAVED";

/*TEST_TELEGRAM_NOTIFICATION*/
export const TEST_TELEGRAM_NOTIFICATION_SENT = "TEST_TELEGRAM_NOTIFICATION_SENT";

/*NAVIGATION*/
export const TOGGLE_OPEN_FILTER_BLOCK_BY_MOUSE = "TOGGLE_OPEN_FILTER_BLOCK_BY_MOUSE";
export const TOGGLE_OPEN_FILTER_BLOCK_BY_BUTTON = "TOGGLE_OPEN_FILTER_BLOCK_BY_BUTTON";

/*Баннеры*/
export const ALL_BANNERS_LOADING = "ALL_BANNERS_LOADING";
export const ALL_BANNERS_LOADED = "ALL_BANNERS_LOADED";
export const ALL_MAIN_BANNERS_LOADING = "ALL_MAIN_BANNERS_LOADING";
export const ALL_MAIN_BANNERS_LOADED = "ALL_MAIN_BANNERS_LOADED";
export const BANNER_SAVED = "BANNER_SAVED";
export const BANNER_DELETED = "BANNER_DELETED";

/*Логи*/
export const LOGS_LOADING = "LOGS_LOADING";
export const LOGS_LOADED = "LOGS_LOADED";
export const LOGS_RESET = "LOGS_RESET";
export const LOG_TYPES_LOADED = "LOG_TYPES_LOADED";

/*Параметы приложений*/
export const APP_PARAM_TYPES_LOADING = "APP_PARAM_TYPES_LOADING";
export const APP_PARAM_TYPES_LOADED = "APP_PARAM_TYPES_LOADED";
export const APP_PARAMS_LOADING = "APP_PARAMS_LOADING";
export const APP_PARAMS_LOADED = "APP_PARAMS_LOADED";
export const APP_PARAM_TYPE_SAVED = "APP_PARAM_TYPE_SAVED";
export const APP_PARAMS_CLEARED = "APP_PARAMS_CLEARED";